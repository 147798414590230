<template>
    <div class="row">
        <div class="col-1 col-lg-1"></div>
        <div class="col-11 col-lg-11 mr-0 outline-area">
            <!-- <div class="outline-area"> -->
                <client-tab></client-tab>
                <div class="table-filter-search">
                    <div class="show">
                        <div class="number-display mr-5">
                            表示数：{{ whitepaperSubscribersCount }}件
                        </div>
                    </div>
                    <div class="search">
                        <i class="icon icon-search"></i>
                        <input
                            class="input input-search pl-8 pr-5"
                            type="text"
                            placeholder="検索"
                            v-model="params.text"
                        />
                    </div>
                </div>
                <div class="table-wrapper">
                    <table class="table table-scroll">
                        <tr>
                            <th class="w-5 none-boder">
                                <div class="icon-arrow" @click="doSort('id')">
                                    ID
                                    <div class="icon-sort" v-if="params.sort != 'id'">
                                        <i class="icon icon-table-arrow-bottom ml-1"></i>
                                        <i class="icon icon-table-arrow-top ml-1"></i>
                                    </div>
                                    <i
                                        v-else
                                        class="icon ml-1"
                                        :class="{
                                        'icon-table-arrow-bottom': isSortDesc('id'),
                                        'icon-table-arrow-top': isSortAsc('id'),
                                        }"
                                    ></i>
                                </div>
                            </th>
                            <th class="none-boder">
                                <div class="icon-arrow" @click="doSort('client_name')">
                                    クライアント名
                                    <div class="icon-sort" v-if="params.sort != 'client_name'">
                                        <i class="icon icon-table-arrow-bottom ml-1"></i>
                                        <i class="icon icon-table-arrow-top ml-1"></i>
                                    </div>
                                    <i
                                        v-else
                                        class="icon ml-1"
                                        :class="{
                                        'icon-table-arrow-bottom': isSortDesc('client_name'),
                                        'icon-table-arrow-top': isSortAsc('client_name'),
                                        }"
                                    ></i>
                                </div>
                            </th>
                            <th class="none-boder">
                                お名前
                            </th>
                            <th class="none-boder">
                                メールアドレス
                            </th>
                            <th class="none-boder">
                                電話番号
                            </th>
                            <th class="none-boder">
                                <div class="icon-arrow" @click="doSort('created_at')">
                                    DL日時
                                    <div class="icon-sort" v-if="params.sort != 'created_at'">
                                        <i class="icon icon-table-arrow-bottom ml-1"></i>
                                        <i class="icon icon-table-arrow-top ml-1"></i>
                                    </div>
                                    <i
                                        v-else
                                        class="icon ml-1"
                                        :class="{
                                        'icon-table-arrow-bottom': isSortDesc('created_at'),
                                        'icon-table-arrow-top': isSortAsc('created_at'),
                                        }"
                                    ></i>
                                </div>
                            </th>
                        </tr>
                        <tr v-for="whitepaperSubscriber in whitepaperSubscribers" :key="whitepaperSubscriber.id">
                            <td>
                                {{ whitepaperSubscriber.id }}
                            </td>
                            <td>
                                {{ whitepaperSubscriber.client_name }}
                            </td>
                            <td>
                                {{ whitepaperSubscriber.name }}
                            </td>
                            <td>
                                {{ whitepaperSubscriber.email }}
                            </td>
                            <td>
                                {{ whitepaperSubscriber.phone_number }}
                            </td>
                            <td>
                                {{ whitepaperSubscriber.created_at }}
                            </td>
                        </tr>
                    </table>
                </div>
                <scroll-loader :loader-method="loadMoreData" :loader-disable="loadDisable" :loader-color="'#49ab94'"></scroll-loader>
            <!-- </div> -->
        </div>
        <div class="col-1 col-lg-1"></div>
    </div>
</template>

<script>
import router from '../../routes';
import http2 from '../../commons/http2';
import ClientTab from '../../components/Client/ClientTab.vue';

export default {
    name: 'WhitepaperSubscribersList',
    components: {ClientTab},
    props: {},
    data() {
        return {
            params: {
                page: 1,
                limit: 20,
                text: '',
                sort: 'id',
                order: 'desc'
            },
            whitepaperSubscribersCount: 0,
            whitepaperSubscribers: [],
            loadDisable: false
        }
    },
    watch: {
        'params.text': function() {
            this.getWhitepaperSubscribers(false);
        }
    },
    methods: {
        getWhitepaperSubscribers(isContinous) {
            if(!isContinous) {
                this.params.page = 1;
            } else {
                this.params.page += 1;
            }
            this.loadDisable = false;
            let url = router.resolve({name: 'api.whitepaper-subscribers'}).href;
            http2.getList(url, this.params, (data) => {
                this.whitepaperSubscribersCount = data.all;
                if(!isContinous) {
                    this.whitepaperSubscribers = data.items;
                } else {
                    this.whitepaperSubscribers = [...this.whitepaperSubscribers, ...data.items];
                }
                if(this.params.limit > data.items.length) {
                    this.loadDisable = true;
                }
            }, () => {
                this.loadDisable = true;
            })
        }, loadMoreData() {
            this.getWhitepaperSubscribers(true);
        },
        doSort(sortColumn) {
            if(sortColumn == this.params.sort) {
                let currentOrder = this.params.order;
                this.params.order = currentOrder == 'asc' ? 'desc' : 'asc'
            } else {
                this.params.sort = sortColumn;
                this.params.order = 'desc';
            }
            this.getWhitepaperSubscribers(false);
        },
        isSortDesc(sortColumn) {
            return this.params.sort == sortColumn && this.params.order == 'desc';
        },
        isSortAsc(sortColumn) {
            return this.params.sort == sortColumn && this.params.order == 'asc';
        }
    },
    mounted() {
        this.getWhitepaperSubscribers(false);
    }

}
</script>

<style lang="scss" scoped>
@import '@/styles/_client.scss';

.icon-sort {
    position: relative;
    min-height: 15px;
    .icon-table-arrow-bottom {
        position: absolute;
        bottom: 0;
    }
    .icon-table-arrow-top {
        position: absolute;
        top: 0;
    }
}
</style>