export const panelTypes = {
    JCP: {
        text: "JCP",
        value: 1,
        key: 'JCP',
        desc: "PlayAdsに登録している全てのユーザーを対象に検証するサービスです。"
    },
    OP: {
        text: "オリジナルパネル",
        value: 2,
        key: 'OP',
        desc: "調査対象者を特定して検証するサービスです。"
    }
}

export const interactionTypes = {
    UNDERSTAND_LOVE: {
        index: 1,
        text: "ナットク/スキ",
        value: {
            interaction_btn_1_text: "ナットク",
            interaction_btn_1_icon_id: process.env.VUE_APP_SUKI_NATTOKU_INTERACTION_BUTTON_1_ICON_ID,
            interaction_btn_1_explain: "理解を表し、わかりやすいと思った瞬間",
            interaction_btn_2_text: "スキ",
            interaction_btn_2_icon_id: process.env.VUE_APP_SUKI_NATTOKU_INTERACTION_BUTTON_2_ICON_ID,
            interaction_btn_2_explain: "好意を表し、好きと思った瞬間",

        }
    },
    GOOD_BAD: {
        index: 2,
        text: "Good/Bad",
        value: {
            interaction_btn_1_text: "Good",
            interaction_btn_1_icon_id: process.env.VUE_APP_GOOD_BAD_INTERACTION_BUTTON_1_ICON_ID,
            interaction_btn_1_explain: "表現が良いと思った瞬間",
            interaction_btn_2_text: "Bad",
            interaction_btn_2_icon_id: process.env.VUE_APP_GOOD_BAD_INTERACTION_BUTTON_2_ICON_ID,
            interaction_btn_2_explain: "不快、嫌と思った瞬間",
        }
    },

}

export const interactionTypesStep1 = {
    DEFAULT: {
        index: 1,
        text: "興味/理解/購買",
        value: {
            interaction_btn_1_text: "興味",
            interaction_btn_1_icon_id: 85,
            interaction_btn_1_explain: "興味があると思ったときにボタンを押してください",
            interaction_btn_2_text: "理解",
            interaction_btn_2_icon_id: 7,
            interaction_btn_2_explain: "理解できたと思ったときにボタンを押してください",
            interaction_btn_3_text: "購買",
            interaction_btn_3_icon_id: 116,
            interaction_btn_3_explain: "買いたいと思ったときにボタンを押してください",
        }
    },
}

export const videoURLTypes = {
    NORMAL: {
        value: 1,
        text: "動画ファイルで調査",
        desc: "動画の拡張子はmp4で、コーデックはH264のみ利用できます。",
        id: 'video-file'
    },
    YOUTUBE: {
        value: 2,
        text: "YouTubeURLで調査",
        desc: "YouTube動画IDはYouTubeのURLのv=後の11桁の文字列です。\nYoutubeショート動画のYoutube 動画IDはURLのshorts/の後の11桁の文字列です。",
        id: 'youtube-url'
    }
}

export const statusTypes = {
    SHOW: {
        value: 1,
        text: "有効"
    },
    HIDE: {
        value: 0,
        text: "無効"
    }
}

export const segmentSwich = {
    OFF: {
        key: 'OFF',
        desc: "男女、20～60代、全国"
    }
}

export const cellFlg = {
    FIRST_COLUMN: {
        value: 0,
        text: "cell_notes"
    },
    FREE_NOTE: {
        value: 1,
        text: "free_notes"
    }
}
export const videoTimeRange = [
    {
        name: 'すべての動画',
        value: 0,
        id: 'avg'
    },
    {
        name: '15秒以下の動画',
        value: 1,
        id: 'avg-0-15'
    },
    {
        name: '30秒以下の動画',
        value: 2,
        id: 'avg-16-30'
    },
    {
        name: '60秒以下の動画',
        value: 3,
        id: 'avg-31-60'
    },
    {
        name: '61秒以上の動画',
        value: 4,
        id: 'avg-61'
    }
]

export const verifyMethods = {
    TWO_TYPES: {
        text: "2種類の反応値を検証",
        value: 1
    },
    ALTERNATELY_TWO_TYPES: {
        text: "2種類の反応値を交互に検証",
        value: 2
    },
    ONE_TYPE: {
        text: "1種類の反応値を検証",
        value: 3
    }

}

export const interactionTypeVerifyOne = {
    UNDERSTAND: {
        index: 1,
        text: "ナットク",
        value: {
            interaction_btn_1_text: "ナットク",
            interaction_btn_1_icon_id: process.env.VUE_APP_SUKI_NATTOKU_INTERACTION_BUTTON_1_ICON_ID,
            interaction_btn_1_explain: "理解を表し、わかりやすいと思った瞬間"
        }
    },
    GOOD: {
        index: 2,
        text: "Good",
        value: {
            interaction_btn_1_text: "Good",
            interaction_btn_1_icon_id: process.env.VUE_APP_GOOD_BAD_INTERACTION_BUTTON_1_ICON_ID,
            interaction_btn_1_explain: "表現が良いと思った瞬間"
        }
    },

}

export const colorInteraction = {
    1: {
        color: '#4976AB'
    },
    2: {
        color: '#E5BB26'
    },
    3: {
        color: '#CE6060'
    },
    4: {
        color: '#CF2F99'
    },
    5: {
        color: '#AB7249'
    }
}

export const backgroundColorInteraction = {
    1: {
        color: '#EDF1F7'
    },
    2: {
        color: '#FBF5DF'
    },
    3: {
        color: '#FEF4F4'
    },
    4: {
        color: '#FAEAF5'
    },
    5: {
        color: '#F7F1ED'
    }
}

export const FREE_PLAN_VALUE = 0
export const LITE_PLAN_VALUE = 1
export const BASIC_PLAN_VALUE = 2
export const ADVANCE_PLAN_VALUE = 3

export const planTypes = {
    FREE_PLAN: {
        text: "FREEプラン",
        value: FREE_PLAN_VALUE,
        buyer_plan: 4
    },
    LITE_PLAN: {
        text: "LITEプラン",
        value: LITE_PLAN_VALUE,
        buyer_plan: 3
    },
    BASIC_PLAN: {
        text: "BASICプラン",
        value: BASIC_PLAN_VALUE,
        buyer_plan: 3
    },
    ADVANCE_PLAN: {
        text : "ADVANCEプラン",
        value : ADVANCE_PLAN_VALUE,
        buyer_plan: 7
    },
}

export const PLAN_TYPE = {
    TRIAL: 1,
    BASIC: 2,
    SPOT: 3,
    FREE: 4,
    MARKET: 5,
    MARKET_PRIVATE: 6,
    ADVANCE: 7
}

export const VIDEO_PLAN_TYPE_ENUM = {
    FREE: 0,
    LITE: 1,
    BASIC: 2,
    ADVANCE: 3
}

export const VIDEO_PLAN_TYPE = [
    {
        id: VIDEO_PLAN_TYPE_ENUM.FREE,
        text: 'FREE'
    },
    {
        id: VIDEO_PLAN_TYPE_ENUM.LITE,
        text: 'LITE'
    },
    {
        id: VIDEO_PLAN_TYPE_ENUM.BASIC,
        text: 'BASIC'
    },
    {
        id: VIDEO_PLAN_TYPE_ENUM.ADVANCE,
        text: 'ADVANCE'
    },

]

export const SETTING_SEGMENT_SAME_AS_PREVIOUS_VALUE = 1
export const SETTING_SEGMENT_CUSTOM_VALUE = 2

export const segmentSettingList = {
    SETTING_SEGMENT_SAME_AS_PREVIOUS: {
        text: "過去に登録したセグメントと同じ設定にする",
        value: SETTING_SEGMENT_SAME_AS_PREVIOUS_VALUE
    },
    SETTING_SEGMENT_CUSTOM: {
        text: "独自のセグメント設定をする",
        value: SETTING_SEGMENT_CUSTOM_VALUE
    },
}

export const CUSTOM_SEGMENT_ON_VALUE = 1
export const CUSTOM_SEGMENT_OFF_VALUE = 0
export const hasCustomSegmentList = {
    CUSTOM_SEGMENT_ON: {
        text: "ON",
        value: CUSTOM_SEGMENT_ON_VALUE
    },
    CUSTOM_SEGMENT_OFF: {
        text: "OFF",
        value: CUSTOM_SEGMENT_OFF_VALUE
    },
}

export const LABEL_UNIT_VALUE = 1
export const MOVIE_UNIT_VALUE = 2
export const USER_UNIT_VALUE = 3

export const targetsForComparison = {
    LABEL_UNIT: {
        text: "ラベル単位",
        value: LABEL_UNIT_VALUE
    },
    MOVIE_UNIT: {
        text: "動画単位",
        value: MOVIE_UNIT_VALUE
    },
    USER_UNIT: {
        text: "ユーザー単位",
        value: USER_UNIT_VALUE
    }
}

export const TYPE_REACTION = {
    STAR: 1,
    CREATIVE: 2,
    CART: 5
}

export const ORDER_COMMENT = {
    INTERACTION: {
        name: "反応数順",
        value: 1,
    },
    VIDEO: {
        name: "動画順",
        value: 2,
    }
}

export const COMMENT_TYPE = {
    verb: {
        name: "動詞",
        value: "verb",
    },
    noun: {
        name: "名詞",
        value: "noun",
    },
    adj: {
        name: "形容詞",
        value: "adj",
    },
    inter: {
        name: "感動詞",
        value: "inter",
    },
}

export const LABEL_TYPE_REACTION = [
    { color: "#CE6060", text: "興味", backgroundColor: '#FEF4F4', id: 0 },
    { color: "#E6BB26", text: "理解", backgroundColor: '#FBF5DF', id: 1 },
    { color: "#AB7249", text: "購買", backgroundColor: '#F7F1ED', id: 2 },
];

export const COLOR_CLASS = ['good', 'nattoku', 'bad', 'suki', 'purchase']

export const COLOR_INTERACTION = {
    1: 'good',
    2: 'nattoku',
    3: 'bad',
    4: 'suki'
}

export const stepList = [
    { id: 1, text: "反応検証（3つボタン）" },
    { id: 2, text: "反応検証（2つボタン）" },
    { id: 3, text: "放映検証" },
]

export const LIST_QUESTION_INTERACTION = [
    {id: 1, name: "CMの認知", image_url: "/images/svg/icon_interaction_0.svg"},
    {id: 2, name: "CMに好意", image_url: "/images/svg/icon_interaction_1.svg"},
    {id: 3, name: "印象に残る", image_url: "/images/svg/icon_interaction_2.svg"},
]

export const stepListInfo = {
    step1 : {
        name: "反応検証（3つボタン）",
        id: 1,
    },
    step2 : {
        name: "反応検証（2つボタン）",
        id: 2,
    },
    step3 : {
        name: "放映検証",
        id: 3,
    },
}

export const listMediaType = [
    {
        id: 1,
        name: "CM",
    },
    {
        id: 2,
        name: "YouTube",
    },
    {
        id: 99,
        name: "その他",
    },
]

export const listPlanVideoType = {
    free: {
        id: 0,
        name: "FREE",
    },
    basic: {
        id: 2,
        name: "BASIC",
    },
    advance: {
        id: 3,
        name: "ADVANCE",
    },
}

export const distributionMapPosition = {
    bottom_left: 1,
    upper_left: 2,
    bottom_right: 3,
    upper_right: 4
}

export const chartType = [
    {
        value: 1,
        name: 'Percentage',
        interaction_tally: 1,
        yAxis:'%',
        chart: 'summary'
    },
    {
        value: 2,
        name: 'Reaction',
        interaction_tally: 0,
        yAxis: 'Reaction',
        chart: 'summary'
    },
    {
        value: 3,
        name: '25%',
        interaction_tally: 0,
        yAxis:'Reaction',
        chart: 'summary'
    },
    {
        value: 4,
        name: '認知',
        interaction_tally: 0,
        yAxis:'Reaction',
        chart: 'cognition'
    },
    {
        value: 5,
        name: '非認知',
        interaction_tally: 0,
        yAxis:'Reaction',
        chart: 'cognition'
    },
]

export const chartTypeStep1 = [
    {
        value: 1,
        name: "Reaction",
        interaction_tally: 0,
        yAxis: "Reaction",
        chart: "summary",
    },
    {
        value: 2,
        name: "Percentage",
        interaction_tally: 1,
        yAxis: "%",
        chart: "summary",
    },
]

export const listKeywordClient = {
    twitter: {
        color: "#1DA1F2",
        label: "Twitter トレンド",
    },
    google: {
        color: "#DB4437",
        label: "Google トレンド",
    },
}

export const intentionLevels = [
    {
        level: 0,
        value: "全体",
        checkFlg: true
    },
    {
        level: 5,
        value: "高い",
        checkFlg: true
    },
    {
        level: 4,
        value: "やや高い",
        checkFlg: true
    },
    {
        level: 3,
        value: "普通",
        checkFlg: true
    },
    {
        level: 2,
        value: "やや低い",
        checkFlg: true
    },
    {
        level: 1,
        value: "低い",
        checkFlg: true
    }
]

export const intentionModes = {
    percentage: {
        value: 1,
        name: "パーセント表示",
    },
    chart: {
        value: 2,
        name: "グラフ表示",
    },
}

export const IMPLEMENT_COGNITION_SURVEY = 1;
export const NOT_IMPLEMENT_COGNITION_SURVEY = 0;
export const listSegmentAttributeIdDefault = [5036,5037,5134,5135,5136,5137,5138]
export const RS_LINKAGE_STATUS = {
    "ON" : 1,
    "OFF" : 0,
}

export const commentDiscoveryStatus = {
    ON: {
        value: 1,
        text: "実施する"
    },
    OFF: {
        value: 0,
        text: "実施しない"
    }
}

export const manualTrafficStatus = {
    ON: {
        value: 1,
        text: "必要"
    },
    OFF: {
        value: 0,
        text: "不要"
    }
}

export const rsLinkageStatusInput = {
    OFF: {
        value: 0,
        text: "手動"
    },
    ON: {
        value: 1,
        text: "自動"
    },
}

export const summaryCommentIdealTypes = {
    topPositive: 1,
    topNegative: 2,
    lowerPositive: 3,
    lowerNegative: 4
}

export const listChartMode = {
    line: {
        value: 1,
        name: "折れ線グラフ",
    },
    column: {
        value: 2,
        name: "棒グラフ",
    }
}

export const evenTypeComment = {
    btn1: 2,
    btn2: 3,
}

export const videoIntentionStatus = {
    enabled: 2,
    disabled: 0,
}

export const otherCommentFlg = {
    enabled: 1,
    disabled: 0,
}

export const dataModeSegment = {
    default: 1,
    attribute: 2,
}

export const targetStatus = {
    enabled: 1,
    disabled: 0
}

export const listAgent = {
    IE: "Internet Explorer",
    EDGE: "Edge",
    CHROME: "Google Chrome",
    SAFARI: "Safari",
    FIREFOX: "FireFox",
    OPERA: "Opera",
}

export const rsSyncStatus = {
    completed: 1,
    error: 2,
    not_available: 3
}