<template>
  <div class="row justify-content-center mt-3">
    <div class="col-11" v-show="!stepSubmitted">
      <SelectStep
          :listStep="listStep"
          :stepChanged="video.deliver_period_type"
          :data="clients"
          :idSelected="buyerId"
          :isSelected="isBuyerId"
          :element="'clients'"
          :clients="clients"
          :currentUser="currentUser"
          @changeStep="changeStep"
          @submitStep="submitStep"
          @selectBuyer="selectBuyer"
      >
      </SelectStep>
    </div>
    <div class="col-11" v-show="stepSubmitted">
      <div class="font-bold title-page" v-if="video.deliver_period_type">{{listStep["step" + video.deliver_period_type].name}}</div>
      <div class="form-group required" v-if="currentUser.role == 'admin'">
        <label>クライアント</label>
        <div class="form-control buyer-select">
          {{ buyerSelected.name }}
        </div>
      </div>
      <div
          class="form-group required"
          :class="{ error: submitted && $v.video.name.$error }"
      >
        <label>{{ $t("texts.video.name") }}</label>
        <input
            class="form-control input-tag-font"
            type="text"
            placeholder="動画名を入れてください"
            v-model.trim="video.name"
        />
        <template v-if="submitted && $v.video.name.$error">
          <span class="error error-message" v-if="!$v.video.name.required">{{
              $t("validation.required", { field: $t("texts.video.name") })
            }}</span>
          <span
              class="error error-message"
              v-else-if="!$v.video.name.maxLength"
          >{{
              $t("validation.max_length", {
                field: $t("texts.video.name"),
                length: 30,
              })
            }}</span
          >
          <span
              class="error error-message"
              v-else-if="!$v.video.name.videoNameXssCheck"
          >{{
              $t("validation.registerVideo.video_name.xss_check", { field: $t("texts.video.name") })
            }}</span
          >
        </template>
      </div>
      <div class="form-group required" v-show="video.deliver_period_type === listStep.step3.id">
        <label>媒体</label>
        <div class="list-radio">
          <div
              class="form-radio-box mr-5"
              v-for="(type, key) in listMediaType"
              :key="'media-type-' + key"
          >
            <input
                class="form-radio-input"
                type="radio"
                :value="type.id"
                v-model="video.commercial_media_type"
            />
            <span class="btn-radio"></span>
            <label class="label-radio">{{ type.name }}</label>
          </div>
        </div>
      </div>
      <div class="form-group form-group-label w-full">
        <label>ラベル</label>
        <div class="group-tag-list">
          <div
            class="tag tag-two"
            :class="{
              active: checkExistSelectedLabel(label.id),
            }"
            v-for="label in labels"
            :key="'label-' + label.id"
            @click="selectLabels(label.id)"
          >
            <div>{{ label.name }}</div>
          </div>
          <div
            class="tag btn-plus"
            @click="addLabel"
          >
            <i class="icon icon-third-plus"></i>
          </div>
        </div>
      </div>
      <div class="d-flex datetime-content">
        <div
            class="form-group required"
            :class="{
              error:
                submitted && ($v.video.start_at.$error || $v.video.end_at.$error),
            }"
        >
          <label>{{ $t("texts.video.start_end_date") }}</label>
          <input
              class="form-control input-tag-font"
              id="datepicker"
              type="text"
              name="daterange"
              placeholder="検証期間を選択"
          />
          <template
              v-if="
            submitted && ($v.video.start_at.$error || $v.video.end_at.$error)
          "
          >
          <span
              class="error error-message"
              v-if="!$v.video.start_at.required || !$v.video.end_at.required"
          >{{
              $t("validation.required", {
                field: $t("texts.video.start_end_date"),
              })
            }}</span
          >
          </template>
          <div class="title-small color-red mt-2">
            <template v-if="currentUser.role === 'admin' &&
                  video.deliver_period_type == listStep.step2.id"
            >
              {{ $t("texts.video.start_end_date_desc") }}
            </template>
          </div>
        </div>
        <div
            class="form-group required"
            v-show="video.deliver_period_type === listStep.step2.id"
            :class="{
              error:
                submitted && ($v.video.start_time_at.$error),
            }"
        >
          <label>{{ $t("texts.video.start_time") }}</label>
          <input
              class="form-control"
              id="timepicker"
              type="text"
          />
          <template
              v-if="
            submitted && ($v.video.start_time_at.$error)
          "
          >
            <span
                class="error error-message"
                v-if="!$v.video.start_time_at.required"
            >
              {{
                $t("validation.required", {
                  field: $t("texts.video.start_time"),
                })
              }}
            </span>
          </template>
        </div>
      </div>
      <div
        class="form-group required"
        :class="{
          error: submitted && $v.video.country_id.$error,
        }"
      >
        <label>{{ $t("texts.video.country") }}</label>
        <Select
          :data="countries"
          :idSelected="video.country_id"
          :isSelected="isCountry"
          @select="selectCountries"
          :element="'countries'"
        ></Select>
        <template v-if="submitted && $v.video.country_id.$error">
          <span
            class="error error-message"
            v-if="!$v.video.country_id.required"
            >{{
              $t("validation.required", { field: $t("texts.video.country") })
            }}</span
          >
        </template>
      </div>
      <div class="form-group required large">
        <label>{{ $t("texts.video.video_url_type") }}</label>
        <div class="list-radio">
          <div
            class="form-radio-box mr-5"
            v-for="(type, key) in videoURLTypes"
            :key="'video-url-type-' + key"
            :class="{'radio-disabled': isUploadingVideoCapture}"
          >
            <input
              :id="type.id"
              class="form-radio-input"
              type="radio"
              :value="type.value"
              v-model="video.video_url_type"
              :disabled="isUploadingVideoCapture"
            />
            <span class="btn-radio"></span>
            <label class="label-radio">{{ type.text }}</label>
          </div>
        </div>
      </div>
      <div
        id="dropbox-file-upload"
        class="
          file-upload-wrapper
          mb-2
          row
          justify-content-center
          align-items-center
        "
        :class="{
          'file-upload-wrapper-disabled': isUploadingVideoCapture,
        }"
        v-show="video.video_url_type == videoURLTypes.NORMAL.value"
        @dragover.prevent
        @drop="handleDrop($event)"
      >
        <label class="color-primary d-flex align-items-center">
          <i class="icon icon-check mr-2" v-if="isVideoFile"></i>
          {{ fileName }}
        </label>
        <div class="input-file">
          <label class="color-primary" for="file-upload">
            <i class="icon icon-download mr-1"></i>
            ファイルを選択
          </label>
          <input
            type="file"
            id="file-upload"
            @change="uploadFile($event)"
            accept=".mp4"
            ref="fileUpload"
            :disabled="isUploadingVideoCapture"
          />
        </div>
      </div>

      <div
        class="d-flex mb-2 align-items-center"
        v-show="video.video_url_type == videoURLTypes.YOUTUBE.value"
      >
        <label class="color-black mr-4">
          {{ $t("texts.video.youtube_id") }}
        </label>
        <div
          class="form-group custom mb-0"
          :class="{
            error:
              submitted &&
              video.video_url_type == videoURLTypes.YOUTUBE.value &&
              $v.video.video_url.$error,
          }"
        >
          <input
            class="form-control input-youtube-url"
            :class="{ 'input-disabled': isUploadingVideoCapture }"
            v-model.trim="youtubeURL"
            placeholder="https://www.youtube.com/watch?v="
            :disabled="isUploadingVideoCapture"
          />
        </div>
      </div>
      <div class="title-small color-black-middle">
        <template v-if="video.video_url_type == videoURLTypes.NORMAL.value">{{
          videoURLTypes.NORMAL.desc
        }}</template>
        <template v-else>{{ videoURLTypes.YOUTUBE.desc }}</template>
      </div>
      <template
        v-if="submitted && video.video_url_type == 1 && $v.video_file.$error"
        class="invalid-feedback"
      >
        <span class="error error-message" v-if="!$v.video_file.required">{{
          $t("validation.registerVideo.video_file.required")
        }}</span>
        <span
          class="error error-message"
          v-else-if="!$v.video_file.extension"
          >{{ $t("validation.registerVideo.video_file.extension") }}</span
        >
        <span class="error error-message" v-else-if="!$v.video_file.accept">{{
          $t("validation.registerVideo.video_file.accept")
        }}</span>
      </template>
      <template
        v-if="
          submitted && video.video_url_type == 2 && $v.video.video_url.$error
        "
        class="invalid-feedback"
      >
        <span
          class="error error-message"
          v-if="!$v.video.video_url.required"
          >{{ $t("validation.registerVideo.video_url.required") }}</span
        >
        <span
          class="error error-message"
          v-else-if="!$v.video.video_url.maxLength"
        >
          {{
            $t("validation.registerVideo.video_url.max_length")
          }}</span
        >
        <span
          class="error error-message"
          v-else-if="!$v.video.video_url.halfWidthExcludeXss"
        >
          {{
            $t("validation.registerVideo.video_url.half_width_exclude_xss")
          }}</span
        >
      </template>
      <template
        v-if="videoCaptureErrorMessage && !$v.video_file.$error"
        class="invalid-feedback w-full"
      >
        <span class="error error-message">{{
          videoCaptureErrorMessage
          }}</span>
      </template>
      <div class="form-group mt-7">
        <div class="form-check-box">
          <input
            id="youtube-analytics"
            class="form-check-input"
            type="checkbox"
            name="youtube-channel"
          />
          <span class="btn-check"> </span>
          <label class="label-check">YouTubeの視聴率を取り込む</label>
        </div>
      </div>
      <div
        class="form-group required"
        v-show="isYoutubeAnalytics"
        :class="{
          error:
            submitted &&
            $v.video.youtube_analytic_id &&
            $v.video.youtube_analytic_id.$error &&
            isYoutubeAnalytics == true,
        }"
      >
        <label>チャンネル</label>
        <Select
          :data="youtubeChannels"
          :idSelected="video.youtube_analytic_id"
          :isSelected="isChannel"
          @select="selectChannel"
          :element="'channels'"
        ></Select>
        <template
          v-if="
            submitted &&
            $v.video.youtube_analytic_id &&
            $v.video.youtube_analytic_id.$error &&
            isYoutubeAnalytics == true
          "
          class="invalid-feedback d-block"
        >
          <span
            class="error error-message"
            v-if="!$v.video.youtube_analytic_id.required"
            >{{
              $t("validation.registerVideo.youtube_video_id.required")
            }}</span
          >
        </template>
      </div>
      <div
        class="form-group custom"
        v-show="isYoutubeAnalytics"
        :class="{
          error:
            submitted &&
            $v.video.youtube_video_id &&
            $v.video.youtube_video_id.$error &&
            isYoutubeAnalytics == true,
        }"
      >
        <label>{{ $t("texts.video.youtube_id") }}</label>
        <input
          class="form-control input-channel-video"
          v-model.trim="youtubeVideoChannelURL"
          placeholder="https://www.youtube.com/watch?v="
        />
        <template
          v-if="
            submitted &&
            $v.video.youtube_video_id &&
            $v.video.youtube_video_id.$error &&
            isYoutubeAnalytics == true
          "
          class="invalid-feedback d-block"
        >
          <span
            class="error error-message"
            v-if="!$v.video.youtube_video_id.required"
            >{{
              $t("validation.registerVideo.youtube_video_id.required")
            }}</span
          >
          <span
            class="error error-message"
            v-else-if="!$v.video.youtube_video_id.maxLength"
          >
            {{
              $t("validation.registerVideo.youtube_video_id.max_length")
            }}</span
          >
          <span
            class="error error-message"
            v-else-if="!$v.video.youtube_video_id.halfWidthExcludeXss"
          >
            {{
              $t("validation.registerVideo.youtube_video_id.half_width_exclude_xss")
            }}</span
          >
        </template>
      </div>

      <!-- interaction button start -->
      <div class="form-group w-full flex flex-col" v-show="video.deliver_period_type === listStep.step2.id">
        <label>反応値の検証方法</label>
        <div class="radio-list flex flex-row flex-wrap">
          <div
            class="form-radio-box mr-5"
            v-for="(type, key) in verifyMethods"
            :key="'verify-methods-' + key"
          >
            <input
              class="form-radio-input"
              type="radio"
              :value="type.value"
              v-model="video.deliver_flg"
            />
            <span class="btn-radio"></span>
            <label class="label-radio">{{ type.text }}</label>
          </div>
        </div>
        <div class="verification-methods-description">
          2種類の反応値を検証：1回の再生で2種類の反応値ボタンを設置して検証 <br>
          2種類の反応値を交互に検証：2回の再生で1種類の反応値ボタンを交互に設置して検証 <br>
          1種類の反応値を検証：1回の再生で1種類の反応値ボタンを設置して検証 <br>
        </div>
      </div>
      <div class="form-group large flex flex-col" v-show="video.deliver_period_type !== listStep.step3.id">
        <label>反応値種別</label>
        <template v-if="video.deliver_period_type === listStep.step2.id">
          <div class="radio-list flex flex-row flex-wrap" v-if="video.deliver_flg != 3">
            <div
                class="form-radio-box mr-5"
                v-for="(type, key) in interactionTypes"
                :key="'interaction-type' + key"
            >
              <input
                  class="form-radio-input"
                  type="radio"
                  :value="type.index"
                  v-model="video.interaction_btn_1_index"
              />
              <span class="btn-radio"></span>
              <label class="label-radio mb-3">{{ type.text }}</label>
            </div>
          </div>
          <div class="radio-list flex flex-row flex-wrap" v-else>
            <div
                class="form-radio-box mr-5"
                v-for="(type, key) in interactionTypeVerifyOne"
                :key="'interaction-type' + key"
            >
              <input
                  class="form-radio-input"
                  type="radio"
                  :value="type.index"
                  v-model="video.interaction_btn_2_index"
              />
              <span class="btn-radio"></span>
              <label class="label-radio">{{ type.text }}</label>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="radio-list flex flex-row flex-wrap">
            <div
                class="form-radio-box mr-5"
                v-for="(type, key) in interactionTypesStep1"
                :key="'interaction-type' + key"
            >
              <input
                  class="form-radio-input"
                  type="radio"
                  :value="type.index"
                  v-model="video.interaction_btn_3_index"
              />
              <span class="btn-radio"></span>
              <label class="label-radio">{{ type.text }}</label>
            </div>
          </div>
        </template>
        <div class="text-link-setting-interaction-button">
          <span style="cursor : pointer" @click="showCustomInteractionModal()">他の反応値を設定 ></span>
        </div>
      </div>
      <!-- interaction button end -->

      <!-- is_admin_change start -->
      <div class="form-group w-full"
           v-show="currentUser.role === 'admin' &&
            video.deliver_period_type == listStep.step2.id"
      >
        <label class="color-red">RE社修正対応フラグ</label>
        <div class="list-radio">
          <div
            class="form-radio-box mr-5"
            v-for="(type, key) in manualTrafficStatus"
            :key="'status-type-' + key"
            :class="{'radio-disabled': adminChangeDisabled}"
          >
            <input
              class="form-radio-input"
              type="radio"
              :value="type.value"
              v-model="video.is_admin_change"
              :disabled="adminChangeDisabled"
              @change="onAdminChangeUpdate($event)"
            />
            <span class="btn-radio"></span>
            <label class="label-radio color-red">{{ type.text }}</label>
          </div>
        </div>
        <div class="describe-segment">
          必要：RE社に調査票や割付の修正対応を依頼するときに選択してください。（サンプル数の設定・特殊なセグメントの利用など）<br>
          &nbsp; &nbsp; &nbsp; &nbsp; 修正が必要なオプション（スクリーニング等）を選択した際は自動的に「必要」が選択されます。
        </div>
      </div>
      <!-- is_admin_change end -->

      <!-- screening start-->
      <div
        v-show="
          video.deliver_period_type !== listStep.step3.id &&
          buyerSelected.plan !== planTypes.FREE_PLAN.buyer_plan
        "
        class="form-group sub-form-group flex flex-col w-full"
      >
        <div class="screening-options">
          <p>スクリーニングオプション</p>
          <button @click="openFormScreeningOption" class="d-flex" :class="{'screening-options-done': settingOptionDone}">
            <i v-if="settingOptionDone" class="icon icon-check-small mr-1"></i>
            {{ settingOptionDone ? 'スクリーニングオプション設定済み' : 'スクリーニングオプション設定'}}
          </button>
        </div>
      </div>
      <!-- screening end-->

      <!-- cognition_survey start -->
      <div class="form-group" v-show="video.deliver_period_type == listStep.step2.id">
        <label>認知度調査</label>
        <div class="list-radio">
          <div class="form-radio-box mr-5">
            <input
              class="form-radio-input implement"
              type="radio"
              value="1"
              name="cognition_survey"
            />
            <span class="btn-radio"></span>
            <label class="label-radio">実施する</label>
          </div>
          <div class="form-radio-box mr-5">
            <input
              class="form-radio-input not-implement"
              type="radio"
              value="0"
              name="cognition_survey"
            />
            <span class="btn-radio"></span>
            <label class="label-radio">実施しない</label>
          </div>
        </div>
      </div>
      <!-- cognition_survey end -->

      <!-- is_rs_cdai start -->
      <div
          class="form-group"
          v-show="video.deliver_period_type == listStep.step2.id"
      >
        <label>コメントディスカバリーAI</label>
        <div class="list-radio">
          <div
            class="form-radio-box mr-5"
            v-for="(type, key) in commentDiscoveryStatus"
            :key="'comment-discovery-status-' + key"
          >
            <input
              class="form-radio-input"
              type="radio"
              :value="type.value"
              v-model="video.is_rs_cdai"
            />
            <span class="btn-radio"></span>
            <label class="label-radio">{{ type.text }}</label>
          </div>
        </div>
      </div>
      <!-- is_rs_cdai end -->

      <!-- video capture start -->
      <div class="form-group w-full"
           v-show="
            video.deliver_period_type === listStep.step2.id &&
            video.deliver_flg != verifyMethods.ONE_TYPE.value
           "
      >
        <div v-show="video.is_rs_cdai == commentDiscoveryStatus.ON.value">
          <label>{{ $t('texts.video.cdai_label') }}</label>
          <div class="default-question-cdai" v-if="defaultQuestionCDAI.length">
            <div
                class="question-item intention-item-checkbox"
                v-for="(question, index) in defaultQuestionCDAI"
                :key="'question-item-' + index"
            >
              <i class="icon icon-checkbox"
                 :class="{ checked: checkedQuestionCDAI.default[video.deliver_flg].includes(index) }"
                 @click="updateCheckedQuestionCDAI('default', index)"></i>
              <span class="intention-item-name" v-html="question.text"></span>
            </div>
          </div>
          <label
            v-show="checkedQuestionCDAI.admin_custom.length ||
            checkedQuestionCDAI.buyer_custom.length ||
            currentUser.role === 'admin'
            "
          >
            {{ $t('texts.video.custom_question_cdai_label') }}
          </label>
          <div class="buyer-question-cdai" v-show="checkedQuestionCDAI.buyer_custom.length">
            <div
                class="question-item intention-item-checkbox w-full"
                v-for="(question, index) in checkedQuestionCDAI.buyer_custom"
                :key="'question-item-buyer' + index"
            >
              <i class="icon icon-checkbox"
                 :class="{ checked: question.is_checked }"
                 @click="updateCheckedQuestionCDAI('buyer_custom', index)"></i>
              <span class="intention-item-name">設問：</span>
              <span class="intention-item-name" v-html="question.text"></span>
            </div>
          </div>
          <div class="admin-question-cdai">
            <div class="list-question-cdai-admin">
              <div
                  class="question-item intention-item-checkbox  w-full"
                  :class="{'question-item-error': !$v.checkedQuestionCDAI.admin_custom.$each[index].text.cdaiquestionXssCheck}"
                  v-for="(question, index) in checkedQuestionCDAI.admin_custom"
                  :key="'question-item-admin-' + index"
              >
                <i class="icon icon-checkbox"
                   :class="{ checked: question.is_checked && $v.checkedQuestionCDAI.admin_custom.$each[index].text.cdaiquestionXssCheck }"
                   @click="updateCheckedQuestionCDAI('admin_custom', index)"></i>
                <div class="form-group question-group" :class="{error: !$v.checkedQuestionCDAI.admin_custom.$each[index].text.cdaiquestionXssCheck}">
                  <input
                      class="form-control group-keyword-list-input question-admin-cdai"
                      type="text"
                      :placeholder="$t('placeholder.question_text_cdai')"
                      v-model="question.text"
                      @change="checkEmptyQuestionCDAIAdmin(index)"
                  />
                  <template v-if="!$v.checkedQuestionCDAI.admin_custom.$each[index].text.cdaiquestionXssCheck">
                  <span class="error error-message">
                    {{$t('validation.xss_check', {field: '設問文'})}}
                  </span>
                  </template>
                </div>
              </div>
            </div>
            <div
                v-show="currentUser.role === 'admin'"
                class="btn-plus-intention"
                @click="addQuestionCDAI"
                :class="{'disabled': isDisableAddQuestionCDAI }"
            >
              {{"＋&nbsp;&nbsp;&nbsp;" + $t('texts.video.add_question_cdai') }}
            </div>
            <div class="content-message">
            <span
                class="error error-message"
                v-show="hasMessageEmptyQuestionCDAI"
            >
            {{
                $t("validation.registerVideo.empty_cdai_question")
              }}</span
            >
            </div>
          </div>
        </div>
        <div
          class="list-image-capture"
          v-show="
            videoCaptureData[video.video_url_type].duration > 0 &&
            !isUploadingVideoCapture
          "
          :class="{'error' : hasMessageEmptyCommentScene}"
        >
          <span class="capture-label">{{ $t('texts.video.capture_label') }}</span>
          <span class="count-image-capture">
            {{ $t('texts.video.count_capture_label') }}：{{ videoCaptureData[video.video_url_type].video_comment_scene.length }}
          </span>
          <div class="content-message">
            <span
              class="error error-message"
              v-show="hasMessageEmptyCommentScene"
            >
            {{
                $t("validation.registerVideo.empty_video_comment_scene")
              }}</span
            >
          </div>
          <div class="list-image-content">
            <div
              class="image-capture"
              v-for="index in videoCaptureData[video.video_url_type].duration"
              :key="'image-capture-' + index"
            >
              <div class="image-capture-item">
                <div class="image-wrapper">
                  <img :src="videoCaptureData[video.video_url_type].url + (index) + '.jpg' + '?t=' + new Date().getTime()"
                       :alt="'video-image' + (index)"
                       :data-url-type="videoCaptureData[video.video_url_type].url"
                       :data-index="index"
                       :class="{'loading' : isReplacingVideoCapture && index == secondReplaceVideoCapture}"
                  />
                </div>
                <i
                  class="icon icon-switch-capture"
                  @click="switchVideoCapture(index)">
                </i>
              </div>
              <div class="image-capture-item-checkbox">
                <i
                  class="icon icon-checkbox"
                  :class="{ checked: videoCaptureData[video.video_url_type].video_comment_scene.includes(index) }"
                  @click="checkedVideoCapture(index)">
                </i>
                <span class="second-text">{{ index }}s</span>
              </div>
            </div>
          </div>
        </div>
        <input
          type="file"
          id="upload-video-capture"
          @change="replaceCapture($event)"
          accept=".jpg"
          :disabled="isReplacingVideoCapture"
          hidden
        />
      </div>

      <div class="form-group w-full"
           v-show="
            video.deliver_period_type === listStep.step2.id &&
            isUploadingVideoCapture
           "
      >
        <img class="uploading-video-capture" src="/images/img/loading_video_capture.png" alt="">
      </div>
      <!-- video capture end -->

      <div class="form-group w-full"
         v-show="video.is_rs_cdai && video.deliver_period_type == listStep.step2.id"
      >
        <label>生成AIを利用して結果の示唆を算出</label>
        <div class="radio-list flex flex-row flex-wrap">
          <div class="form-radio-box mr-5">
            <input class="form-radio-input" type="radio" value="1" v-model="video.is_chatgpt"/>
            <span class="btn-radio"></span>
            <label class="label-radio mb-3">利用する</label>
          </div>
          <div class="form-radio-box mr-5">
            <input class="form-radio-input" type="radio" value="0" v-model="video.is_chatgpt"/>
            <span class="btn-radio"></span>
            <label class="label-radio mb-3">利用しない</label>
          </div>
        </div>
        <div class="wrap-chatgpt">
          <label style="cursor: pointer" @click="showChatGpt = true">算出イメージ ></label>
        </div>
      </div>

      <!-- intention start -->
      <div class="form-group w-full intention-content"
           v-show="video.deliver_period_type === listStep.step2.id && intentionList.length > 0 &&
            video.deliver_flg != verifyMethods.ONE_TYPE.value"
      >
        <label class="w-full">{{ $t('texts.video.setting_intention') }}</label>
        <div class="group-intention-list">
          <div
            class="intention-item"
            v-for="(intentionDataItem, index) in intentionData.select_data"
            :key="'intention-data-' + index"
          >
            <div class="intention-item-checkbox">
              <i class="icon icon-checkbox"
                 :class="{ checked: videoIntentionIds.includes(intentionDataItem.id_selected)}"
                 @click="checkedIntention(intentionDataItem.id_selected)"></i>
              <span class="intention-item-name">{{ $t('texts.video.intention_option', { number: index + 1 }) }}</span>
            </div>
            <div class="intention-item-select">
              <SelectCustom
                class="select-intention"
                :data="intentionDataItem.intention_items"
                :isSelected="true"
                :id-selected="intentionDataItem.id_selected"
                :element="'intention-' + index"
                :enable-search="false"
                :index="index"
                @select="selectIntention"
              ></SelectCustom>
            </div>
          </div>
        </div>
        <div
          v-show="!(intentionData.select_data.length === intentionList.length)"
          class="btn-plus-intention"
          @click="addIntention"
        >
          {{"＋&nbsp;&nbsp;&nbsp;" + $t('texts.video.add_intention') }}
        </div>
      </div>
      <!-- intention end -->

      <!-- segment start -->
      <div v-show="video.deliver_period_type !== listStep.step3.id"
           class="form-group sub-form-group flex flex-col w-full">
        <label>セグメントを設定</label>
        <div class="radio-list flex flex-row flex-wrap">
          <div
            class="form-radio-box mr-5"
            v-for="(hasCustomSegment, key) in hasCustomSegmentList"
            :key="'has-custom-segment-' + key"
          >
            <input
              class="form-radio-input"
              type="radio"
              v-model="video.has_custom_segment"
              :value="hasCustomSegment.value"
            />
            <span class="btn-radio"></span>
            <label class="label-radio mb-3">{{ hasCustomSegment.text }}</label>
          </div>
        </div>
        <span class="error error-message"
              v-if="requireOnSettingSegment"
        >
              {{ $t('messages.require_on_segment') }}
        </span>
        <span class="error error-message"
              v-if="
                showModalName !== 'segment-selected' &&
                hasSettingErrorSegment
              "
        >
              {{ $t('messages.setting_segment_error') }}
        </span>
        <span class="error error-message"
              v-if="showModalName !== 'segment-selected' &&
                isSettingErrorSegmentAttribute"
        >
              {{ $t('messages.setting_segment_attribute_error') }}
        </span>
        <template v-if="video.has_custom_segment == CUSTOM_SEGMENT_ON_VALUE">
          <div
              class="preview-group-segment list-attribute flex flex-col my-2"
              v-show="groupsSegment.length > 0 && groupsSegment[0].length > 0"
              :class="{'error' : isSettingErrorSegmentAttribute || hasSettingErrorSegment}"
          >
            <div class="attributes text-left px-5">
              <div
                v-for="(groupAttributes, index) in groupsSegment"
                :key="'group' + index"
                class="list-item py-5"
                :class="{
                    'list-item-selected': groupAttributes.length > 0,
                    'border-none': index + 1 == groupsSegment.length
                  }"
              >
                <div class="title-item pb-3">{{ `セグメント項目 ${index + 1}` }}</div>
                <div class="groups-segment">
                  <div class="describe-wrapper" v-if="groupAttributes.length != 0">
                    <div class="describe-item-selected">
                      <div class="scroll-tab">
                        <div
                          class="att-selected mb-2"
                          v-for="(attribute, index) in groupAttributes"
                          :key="'att-selected-' + index"
                        >
                          <div class="name">{{ attribute.name }}</div>
                          <div class="value inline-flex">
                              <span
                                v-for="(attributeValue, index) in attribute.list"
                                :key="'index' + index + 'value-selected-' + attributeValue.id"
                              >
                                {{ attributeValue.value }}
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="text-link-setting-interaction-button" v-show="video.has_custom_segment === 1">
                <span
                  style="cursor : pointer"
                  @click="openFormAddSegment"
                >セグメント選択画面へ ></span>
        </div>
        <div class="describe-panel">
          {{ segmentSwich.OFF.key }} : {{ segmentSwich.OFF.desc }}
        </div>
        <div v-if="buyerSelected.plan == planTypes.FREE_PLAN.buyer_plan" class="describe-segment">
          スクリーニングオプションはADVANCEプラン、BASICプランの訴求検証・演出検証でご利用いただけます
        </div>
      </div>
      <!-- segment end -->

      <!-- status start -->
      <div class="form-group" v-show="video.deliver_period_type == listStep.step2.id">
        <label>ステータス</label>
        <div class="list-radio">
          <div
            class="form-radio-box mr-5"
            v-for="(type, key) in statusTypes"
            :key="'status-type-' + key"
          >
            <input
              class="form-radio-input"
              type="radio"
              :value="type.value"
              v-model="video.status"
            />
            <span class="btn-radio"></span>
            <label class="label-radio">{{ type.text }}</label>
          </div>
        </div>
      </div>
      <!-- status end -->

      <!-- twitter_keywords start -->
      <div class="form-group form-group-label w-full" v-show="video.deliver_period_type == listStep.step3.id">
        <label>トレンドワード <ToolTip :text="$t('texts.tooltip.keyword')"></ToolTip></label>
        <div class="flex group-keyword-list group-tag-list">
            <input
                v-for="(keyword, index) in video.twitter_keywords"
                :key="'keyword-' + index"
                class="form-control group-keyword-list-input"
                type="text"
                placeholder=""
                v-model.trim="video.twitter_keywords[index]"
            />
          <div
              class="tag btn-plus"
              @click="addKeyword"
          >
            <i class="icon icon-third-plus"></i>
          </div>
        </div>
      </div>
      <!-- twitter_keywords end -->

      <div class="row d-flex justify-content-center">
        <div class="form-group">
          <button
            class="btn btn-primary btn-submit"
            @click="handleNextPage"
            :disabled="
              requireOnSettingSegment ||
              isUploadingVideoCapture ||
              hasSettingErrorSegment ||
              isSettingErrorSegmentAttribute
            "
          >
            次へ
          </button>
        </div>
      </div>
    </div>

    <form-custom-interaction
      :key="keyInteraction"
      :deliverFlg="video.deliver_flg"
      :deliverPeriodType="video.deliver_period_type"
      :isShown="showModalName == 'custom-interaction'"
      @iconsList="getIconsList"
    ></form-custom-interaction>

    <ModalAddLabel
      :clientId="buyerId"
      :isShown="showModalName == 'add-label'"
      @loadPage="getListLabel"
      @closeModal="showModalName = ''"
    ></ModalAddLabel>

    <FormSegmentSelected
      :categories="categories"
      :attributes="attributes"
      @openFormValue="openFormValue"
      :segmentArr="segmentSelected"
      :prevSegmentSelected="JSON.parse(JSON.stringify(segmentSelected))"
      :isShown="showModalName == 'segment-selected'"
      :playAdsLITESegment="video.plan === 1"
      @updateSegmentSelected="updateSegmentSelected"
      @settingAndOrSegment="settingAndOrSegment"
      :isSettingError="hasSettingErrorSegment"
      :errorSelectAttribute="errorSelectAttribute"
    ></FormSegmentSelected>

    <form-multiple
      :attribute="attribute"
      :attributeStatus="attributeStatus"
      @addSegment="addSegment"
      :prevSelected="segmentSelected"
      :isShown="showModalName == 'attribute-multiple'"
    ></form-multiple>

    <FormSingle
      :attribute="attribute"
      :attributeStatus="attributeStatus"
      :prevSelected="segmentSelected"
      @addSegment="addSegment"
      :isShown="showModalName == 'attribute-single'"
    ></FormSingle>

    <FormAndOr
      :segmentArr="segmentSelected"
      @saveSegmentInfo="getSegment"
      :isShown="showModalName == 'and-or'"
      :groups="groupsSegment"
      :prevGroupsSegment="JSON.parse(JSON.stringify(groupsSegment))"
    ></FormAndOr>

    <FormScreeningOption
        :clientId="buyerId"
        :questions="questions"
        :isShown="showModalName == 'screening-option'"
        @addQuestion="addQuestion"
        @removeQuestion="removeQuestion"
        @settingOption="settingOption"
        @resetOption="resetOption"
        @closeModal="closeModalScreeningOption"
    ></FormScreeningOption>

    <ModalAddOption
        :isShown="showModalName == 'add-option'"
        :addOptionInfo="addOptionInfo"
        @addAnswerToQuestion="addAnswerToQuestion"
    ></ModalAddOption>
    <FullScreenLoading
      v-show="is_fullscreen_loading"
      :message="$t('messages.syncing_segment')"
    >
    </FullScreenLoading>

    <div class="modal chat-gpt" v-show="showChatGpt">
      <div class="modal-content">
        <div class="header">
          <div class="btn-close" @click="showChatGpt = false">
            <i class="icon icon-close-no-wrap"></i>
          </div>
        </div>
        <div class="body">
          <h2>生成AIが算出する示唆イメージ</h2>
          <h3>ハーブティーのCM動画を検証した例です。</h3>
          <img src="/images/svg/chat_gpt.svg">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/commons/event_bus";
import flatpickr from "flatpickr";
import moment from "moment";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import router from "@/routes/index";
import { maxLength, required } from "vuelidate/lib/validators";
import { accept, extension, gt, halfWidthExcludeXss, lt, cdaiquestionXssCheck, videoNameXssCheck } from "@/commons/rules";
import {
  listSegmentAttributeIdDefault,
  manualTrafficStatus,
  commentDiscoveryStatus,
  CUSTOM_SEGMENT_OFF_VALUE,
  CUSTOM_SEGMENT_ON_VALUE,
  hasCustomSegmentList,
  interactionTypes,
  interactionTypesStep1,
  interactionTypeVerifyOne,
  listMediaType,
  panelTypes,
  planTypes,
  segmentSettingList,
  segmentSwich,
  SETTING_SEGMENT_CUSTOM_VALUE,
  SETTING_SEGMENT_SAME_AS_PREVIOUS_VALUE,
  statusTypes,
  stepListInfo,
  verifyMethods,
  videoURLTypes,
  IMPLEMENT_COGNITION_SURVEY,
  rsSyncStatus,
} from "@/commons/constants";
import {
  getAttributesActive,
  getListIntention,
  listLabels,
  getRsFeasibilityApiId,
  getRsFeasibilityIds,
  postVideoCapture,
  getVideoCaptureApi, getCaptureUploadUrl, getVideoQuestion,
} from "@/commons/api_service";
import Select from "@/components/Video/Select.vue";
import FormCustomInteraction from "@/components/Project/FormCustomInteraction.vue"
import ModalAddLabel from "@/components/Modal/ModalAddLabel.vue"
import FormSegmentSelected from "@/components/Project/FormSegmentSelected.vue";
import FormMultiple from "@/components/Project/FormMultiple.vue";
import FormSingle from "@/components/Project/FormSingle.vue";
import FormAndOr from "@/components/Segment/FormAndOr.vue";
import FormScreeningOption from "@/components/Project/FormScreeningOption.vue";
import ModalAddOption from "@/components/Modal/ModalAddOption.vue"
import { calendarEventsUrl, forceArrowDownSelect2 } from "@/commons/helpers";
import SelectStep from "@/pages/Video/SelectStep";
import ToolTip from "@/components/Commons/ToolTip.vue";
import SelectCustom from "@/components/Video/SelectCustom.vue";
import FullScreenLoading from "@/components/Commons/FullScreenLoading.vue";
import {intentionFunction, segmentAgeModify} from "@/commons/mixins";

const jQuery = require("jquery");
export default {
  components: {
    FullScreenLoading,
    SelectCustom,
    ToolTip,
    Select,
    FormCustomInteraction,
    ModalAddLabel,
    FormSegmentSelected,
    FormMultiple,
    FormSingle,
    FormAndOr,
    FormScreeningOption,
    ModalAddOption,
    SelectStep,
  },
  mixins: [segmentAgeModify, intentionFunction],
  validations() {
    let TYPE_YOUTUBE_URL = 2;
    let TYPE_VIDEO_FILE = 1;
    let validate = {
      video: {
        name: { required, maxLength: maxLength(30), videoNameXssCheck },
        start_at: { required, lt: lt(this.end_at) },
        end_at: { required, gt: gt(this.start_at) },
        country_id: { required },
      },
      checkedQuestionCDAI: {
        admin_custom: {
          $each: {
            text: { cdaiquestionXssCheck }
          }
        }
      }
    };
    if (this.isYoutubeAnalytics) {
      (validate.video.youtube_video_id = {
        required,
        maxLength: maxLength(11),
        halfWidthExcludeXss,
      }),
        (validate.video.youtube_analytic_id = { required });
    }
    if (this.isYoutubeAnalytics) {
      if (this.video.youtube_analytic_id) {
        validate.video.youtube_video_id = {
          required,
          maxLength: maxLength(11),
          halfWidthExcludeXss,
        };
      }
      if (this.video.youtube_video_id) {
        validate.video.youtube_analytic_id = { required };
      }
    }

    if (this.video.video_url_type == TYPE_VIDEO_FILE) {
      validate.video_file = {
        required,
        extension: extension(["mp4"]),
        accept: accept(["video/mp4"]),
      };
    }
    if (this.video.video_url_type == TYPE_YOUTUBE_URL) {
      validate.video.video_url = {
        required,
        maxLength: maxLength(11),
        halfWidthExcludeXss,
      };
    }

    if (this.video.deliver_period_type == stepListInfo.step2.id) {
      validate.video.start_time_at = { required };
    } else {
      validate.video.start_time_at = {};
    }

    return validate;
  },
  data() {
    let defaultCaptureData = {}
    defaultCaptureData[videoURLTypes.NORMAL.value] = {
      duration: 0,
      url: '',
      video_tmp_id: null,
      video_comment_scene: [],
    }
    defaultCaptureData[videoURLTypes.YOUTUBE.value] = {
      duration: 0,
      url: '',
      video_tmp_id: null,
      video_comment_scene: [],
    }

    let checkedQuestionCDAI = {
      default: {},
      admin_custom: [],
      buyer_custom: [],
    }
    checkedQuestionCDAI.default[verifyMethods.TWO_TYPES.value] = [0]
    checkedQuestionCDAI.default[verifyMethods.ALTERNATELY_TWO_TYPES.value] = [0,1]

    return {
      selectedLabels: [],
      verifyMethods: verifyMethods,
      interactionTypes: {...interactionTypes},
      interactionTypesStep1: {...interactionTypesStep1},
      interactionTypeVerifyOne : {...interactionTypeVerifyOne},
      planTypes,
      segmentSettingList,
      SETTING_SEGMENT_SAME_AS_PREVIOUS_VALUE,
      SETTING_SEGMENT_CUSTOM_VALUE,
      segmentSettingModel: SETTING_SEGMENT_SAME_AS_PREVIOUS_VALUE,
      previousVideoId: null,
      hasCustomSegmentList,
      CUSTOM_SEGMENT_ON_VALUE,
      showModalName: '',
      keyInteraction : 1,
      categories: [],
      attributes: [],
      segmentSelected: [],
      groupsSegment: [[]],
      attribute: {},
      attributeStatus: {},
      labels: [],
      countries: [],
      clients: [],
      clientsDefault: [],
      youtubeChannels: [],
      videoURLTypes: videoURLTypes,
      statusTypes: statusTypes,
      commentDiscoveryStatus,
      manualTrafficStatus,
      video: {
        name: "",
        start_at: null,
        end_at: null,
        start_time_at: "00:00",
        video_url_type: 1,
        video_url: "",
        country_id: null,
        status: 1,
        youtube_analytic_id: null,
        youtube_video_id: "",
        cognition_survey: 0,
        video_file_text: "",
        panel_type: panelTypes.JCP.value,
        label_ids: '',
        deliver_flg: 1,
        interaction_btn_1_index : 1,
        interaction_btn_2_index : 1,
        interaction_btn_3_index : 1,
        has_custom_segment: CUSTOM_SEGMENT_OFF_VALUE,
        deliver_period_type: null,
        commercial_media_type: 1,
        twitter_keywords: ['','','','',''],
        feasibility_ids: [],
        rs_linkage: 0,
        is_rs_cdai: commentDiscoveryStatus.ON.value,
        is_admin_change: manualTrafficStatus.OFF.value,
        forceOnAdminChangeStatus: false,
        listAttributeIdsRs: '',
        is_chatgpt: 1
      },
      buyerId: null,
      buyerSelected: {},
      video_file: null,
      isYoutubeAnalytics: false,
      fileName: "動画ファイルをドラッグ",
      youtubeURL: "https://www.youtube.com/watch?v=",
      youtubeVideoChannelURL: "https://www.youtube.com/watch?v=",
      isVideoFile: false,
      submitted: false,
      isBuyerId: false,
      isCountry: false,
      isChannel: false,
      isCacheData: false,
      currentUser: {},
      holidays: null,
      panelTypes: panelTypes,
      segmentSwich: segmentSwich,
      icons: [],
      prevQuestion: [{
        id: 1, name: 'Q1', value: '', isChecked: {}, options: []
      }],
      questions: [{
        id: 1, name: 'Q1', value: '', isChecked: {}, options: []
      }],
      settingOptionDone: false,
      addOptionInfo: {},
      // new feature
      listMediaType: JSON.parse(JSON.stringify(listMediaType)),
      listStep : JSON.parse(JSON.stringify(stepListInfo)),
      stepSubmitted: false,
      previousPath: null,
      is_fullscreen_loading: false,
      isSettingSegmentError: false,
      requireOnSettingSegment: false,
      adminChangeDisabled: false,
      // for video capture
      isUploadingVideoCapture: false,
      videoFileUrl: null,
      videoCaptureDataDefault: JSON.parse(JSON.stringify(defaultCaptureData)),
      videoCaptureData: JSON.parse(JSON.stringify(defaultCaptureData)),
      videoCaptureErrorMessage: null,
      isReplacingVideoCapture: false,
      secondReplaceVideoCapture: null,
      checkedQuestionCDAI: JSON.parse(JSON.stringify(checkedQuestionCDAI)),
      hasMessageEmptyQuestionCDAI: false,
      hasMessageEmptyCommentScene: false,
      componentDestroyed: false,
      showChatGpt: false,
      flatpickrInstance: null,
      flatpickrInstanceTime: null,
    };
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.previousPath = from.path
    })
  },
  watch: {
    buyerId(val) {
      if (this.clients.length) {
        this.isChannel = false
      }
      this.buyerId = val;
    },
    segmentSelected: {
      handler: function () {
        this.isSettingSegmentError = false
        this.requireOnSettingSegment = false
        this.errorSelectAttribute = null
        this.toggleAgeAttribute()
      },
      deep: true,
    },
    'video.has_custom_segment': {
      handler: function (newValue) {
        if (newValue == CUSTOM_SEGMENT_ON_VALUE) {
          this.requireOnSettingSegment = false
        }
      },
      deep: true,
    },
    youtubeURL: {
      handler: function () {
        this.updateVideoCapture()
      },
      deep: true,
    },
    'video.deliver_flg': {
      handler: function () {
        if (this.videoCaptureData[this.video.video_url_type].duration == 0) {
          this.updateVideoCapture()
        }
      },
      deep: true,
    },
    'video.video_url_type': {
      handler: function () {
        this.updateVideoCapture(true)
      },
      deep: true,
    },
    adminChangeStatus (newValue) {
      this.adminChangeDisabled = newValue.is_disabled
      this.video.is_admin_change = newValue.value
      const noRsFlagSegment = this.segmentSelected.length > 0 && !this.checkRsFlagSegment(this.segmentSelected)
      if (noRsFlagSegment) {
        this.checkHasErrorSelectAttribute()
      }
      if (newValue.value == manualTrafficStatus.ON.value) {
        this.requireOnSettingSegment = false
      } else {
        if (
          this.video.has_custom_segment === CUSTOM_SEGMENT_ON_VALUE &&
          noRsFlagSegment &&
          this.video.feasibility_ids.length === 0 &&
          !this.isSettingSegmentError &&
          !this.isSettingErrorSegmentAttribute &&
          this.showModalName !== 'segment-selected'
        ) {
          this.syncRsSegment(this.segmentSelected)
        }
      }
    },
    "video.deliver_period_type": {
      handler: function () {
        this.initFlatPickr()
      },
      deep: true,
    },
  },
  computed: {
    defaultQuestionCDAI: function () {
      return this.getDefaultQuestionCDAI()
    },
    isDisableAddQuestionCDAI: function () {
      return this.checkedQuestionCDAI.admin_custom.filter(item => !item.text.replace(/\s+/g, '').length).length
    },
    hasCustomCDAIQuestion: function () {
      let hasCustomQuestionChecked = false
      // find question in admin custom is checked
      this.checkedQuestionCDAI.admin_custom.forEach(item => {
        if (item.is_checked) {
          hasCustomQuestionChecked = true
        }
      })
      // find question in buyer custom is checked
      this.checkedQuestionCDAI.buyer_custom.forEach(item => {
        if (item.is_checked) {
          hasCustomQuestionChecked = true
        }
      })

      return hasCustomQuestionChecked
    },
    adminChangeStatus: function () {
      return this.getAdminChangeStatus()
    },
    stringSegmentSelected: function () {
      let stringSegmentSelected = ''
      if (this.segmentSelected.length) {
        let listId = []
        this.segmentSelected.forEach(function (item) {
          if (item.list && item.list.length) {
            item.list.forEach(function (item2) {
              listId.push(item2.id)
            })
          }
        })
        stringSegmentSelected = listId.join(',')
      }

      return stringSegmentSelected
    },
    isSettingErrorSegmentAttribute() {
      return this.errorSelectAttribute &&
          this.errorSelectAttribute.length &&
          this.video.has_custom_segment == CUSTOM_SEGMENT_ON_VALUE &&
          this.video.is_admin_change == manualTrafficStatus.OFF.value
    },
    hasSettingErrorSegment() {
      return this.isSettingSegmentError &&
          this.video.has_custom_segment == CUSTOM_SEGMENT_ON_VALUE &&
          this.video.is_admin_change == manualTrafficStatus.OFF.value
    }
  },
  methods: {
    touchValidation(index) {
      if (this.$v.checkedQuestionCDAI.admin_custom[index]) {
        this.$v.checkedQuestionCDAI.admin_custom[index].text.$touch();
      }
    },
    showCustomInteractionModal() {
      this.toggleModal('custom-interaction')
    },

    toggleModal(modalName) {
      this.showModalName = modalName;
    },

    openFormAddSegment() {
      EventBus.$emit("toggleModal", "segment-selected");
    },

    openFormScreeningOption() {
      EventBus.$emit("toggleModal", "screening-option");
    },

    openFormValue(data) {
      let TYPE_MULTIPLE = 2;
      let TYPE_SINGLE = 1;
      let type = data.type;
      let attribute = data.data;
      this.attributeStatus = this.segmentSelected.find(
        (e) => e.id == attribute.id
      );
      if (type == TYPE_MULTIPLE) {
        this.attribute = attribute;
        this.toggleModal("attribute-multiple");
      } else if (type == TYPE_SINGLE) {
        this.attribute = attribute;
        this.toggleModal("attribute-single");
      }
    },

    updateSegmentSelected(data) {
      this.segmentSelected = data
      this.video.feasibility_ids = []
      this.clearDataUnSelected()
    },

    clearDataUnSelected() {
      let newGroups = [];
      for (let i = 0; i < this.groupsSegment.length; i++) {
        let group = this.groupsSegment[i];
        let newGroup = [];
        group.forEach(attribute => {
          let findAttr =  this.segmentSelected.find(e => e.id == attribute.id);
          if (findAttr) {
            let values = attribute.list.filter(e => findAttr.list.find(a => a.id == e.id));
            if (values.length) {
              newGroup.push({
                ...attribute,
                list: values
              })
            }
          }
        });
        if (newGroup.length) {
          newGroups.push(newGroup);
        }
      }
      newGroups = newGroups.length ? newGroups: [[]];
      this.$set(this, 'groupsSegment', newGroups)
    },

    getListAttribute() {
      getAttributesActive((data) => {
        this.attributes = JSON.parse(JSON.stringify(data));
        this.categories.forEach((category) => {
          category.attributes = this.attributes.filter(
            (attribute) => attribute.category == category.id
          );
        });
      });
    },

    getListCategory() {
      let url = this.$router.resolve({
        name: "api.category",
      }).href;
      this.$http2.get(url, {}, (data) => {
        this.categories = data.items;
        this.getListAttribute();
      });
    },

    addSegment(data) {
      let segmentId = data.segment_id;
      let segmentName = data.segment_name;
      let segmentSelectedList = data.segment_list;
      let obj = {
        id: segmentId,
        name: segmentName,
        category: data.category,
        list: segmentSelectedList,
      };
      let index = this.segmentSelected.findIndex((item) => item.id == obj.id);
      if (index != -1) {
        this.$set(this.segmentSelected, index, obj);
      } else {
        this.segmentSelected.push(obj);
      }
      this.video.feasibility_ids = []
      this.toggleModal("segment-selected");
    },

    getSegment(data) {
      this.groupsSegment = data
      this.toggleModal("")
    },

    selectVideoUrlType() {
      let TYPE_YOUTUBE_URL = 2;
      let TYPE_VIDEO_FILE = 1;
      let vm = this;
      jQuery("#youtube-url").on("click", function () {
        vm.video.video_url_type = TYPE_YOUTUBE_URL;
      });
      jQuery("#video-file").on("click", function () {
        vm.video.video_url_type = TYPE_VIDEO_FILE;
      });
    },
    selectStatus() {
      let vm = this;
      let STATUS_ACTIVE = 1;
      let STATUS_INACTIVE = 0;
      jQuery(".status-active").on("click", function () {
        vm.video.status = STATUS_ACTIVE;
      });
      jQuery(".status-inactive").on("click", function () {
        vm.video.status = STATUS_INACTIVE;
      });
    },
    selectCognitionSurvey() {
      let vm = this;
      let IMPLEMENT_COGNITION_SURVEY = 1;
      let NOT_IMPLEMENT_COGNITION_SURVEY = 0;
      jQuery(".implement").on("click", function () {
        vm.video.cognition_survey = IMPLEMENT_COGNITION_SURVEY;
      });
      jQuery(".not-implement").on("click", function () {
        vm.video.cognition_survey = NOT_IMPLEMENT_COGNITION_SURVEY;
      });
    },
    checkYoutubeAnalytics() {
      let vm = this;
      jQuery("#youtube-analytics").on("click", function () {
        let checked = jQuery(this).is(":checked");
        if (checked) {
          vm.isYoutubeAnalytics = true;
          if (vm.youtubeChannels.length > 0) {
            vm.video.youtube_analytic_id = vm.youtubeChannels[0].id;
          }
        } else {
          vm.isYoutubeAnalytics = false;
          vm.video.youtube_analytic_id = null;
          vm.youtubeVideoChannelURL= "https://www.youtube.com/watch?v=";
        }
      });
    },
    changeInputYoutubeChannelVideoId() {
      let vm = this;
      jQuery(".input-channel-video").change(function () {
        vm.video.youtube_video_id = jQuery(this).val().slice(32);
      });
    },
    selectBuyer(buyerSelected) {
      let previousBuyer = JSON.parse(JSON.stringify(buyerSelected))
      this.buyerId = parseInt(buyerSelected.id);
      this.$store.commit('setBuyerSelected', null)
      this.buyerSelected = this.clients.filter(data => data.id === this.buyerId)[0]

      if (
          !previousBuyer ||
          previousBuyer && previousBuyer.plan != planTypes.ADVANCE_PLAN.buyer_plan && this.buyerSelected.plan == planTypes.ADVANCE_PLAN.buyer_plan ||
          previousBuyer && previousBuyer.plan == planTypes.ADVANCE_PLAN.buyer_plan && this.buyerSelected.plan != planTypes.ADVANCE_PLAN.buyer_plan
      ) {
        this.stepSubmitted = false
        this.$set(this.video, "deliver_period_type", null)
      }

      this.getListLabel();
      this.getListBuyerQuestion(true);
      this.getListChannelYoutube();
    },

    checkExistSelectedLabel(id) {
      return this.selectedLabels.includes(id)
    },

    selectLabels(id) {
      if (!this.checkExistSelectedLabel(id)) {
        this.selectedLabels.push(id)
      } else {
        this.selectedLabels.splice(this.selectedLabels.indexOf(id), 1)
      }
    },

    addLabel() {
      this.toggleModal('add-label')
    },

    selectCountries(id) {
      this.video.country_id = parseInt(id);
    },
    selectChannel(id) {
      this.video.youtube_analytic_id = parseInt(id);
    },
    initFlatPickr() {
      this.initFlatpickrDate()
      if (this.video.deliver_period_type === this.listStep.step2.id) {
        this.initFlatpickrTime()
      }
    },
    initFlatpickrDate() {
      let vm = this;
      let now = moment();
      let currentDate = now.format("YYYY-MM-DD");
      let htmlNextButton =
          "<div class='btn button-next flatpickr-next-month'><img src='./images/svg/icon_next.svg'></div>";
      let htmlPrevButton =
          "<div class='btn button-prev flatpickr-prev-month'><img src='./images/svg/icon_prev.svg'></div>";

      Japanese.rangeSeparator = " ~ ";
      this.flatpickrInstance = flatpickr("#datepicker", {
        static: true,
        locale: Japanese,
        enableTime: false,
        showMonths: 2,
        mode: "range",
        minDate: currentDate,
        dateFormat: "Y/m/d",
        // allowInput: vm.video.deliver_period_type === vm.listStep.step2.id,
        // altInput: vm.video.deliver_period_type === vm.listStep.step2.id,
        // altFormat: "Y/m/d",
        defaultDate: [vm.video.start_at, vm.video.end_at],
        time_24hr: true,
        nextArrow: htmlNextButton,
        prevArrow: htmlPrevButton,
        onDayCreate: (dObj, dStr, fp, dayElem) => {
          let dateObj = dayElem.dateObj;
          if (dateObj.toDateString().substr(0, 3) == "Sat") {
            jQuery(dayElem).addClass("is-saturday");
          }
          if (dateObj.toDateString().substr(0, 3) == "Sun") {
            jQuery(dayElem).addClass("holidays");
          }
          if (vm.holidays) {
            if (
                vm.holidays.indexOf(moment(dateObj).format("YYYY-MM-DD")) !== -1
            ) {
              if (jQuery(dayElem).hasClass("is-saturday")) {
                jQuery(dayElem).removeClass("is-saturday");
              }
              jQuery(dayElem).addClass("holidays");
            }
          }
        },
        onChange(selectedDates, dateStr, instance) {
          let _this = this;
          let dateArr = selectedDates.map(function (date) {
            return _this.formatDate(date, "Y/m/d");
          });
          let start = dateArr[0];
          let end = dateArr[1];
          // only for step 2 end date must be 7 days after start date
          if (vm.video.deliver_period_type === vm.listStep.step2.id && end != undefined) {
            let startDate = moment(start, "YYYY/MM/DD");
            let endDate = moment(end, "YYYY/MM/DD");
            let minEndDate = startDate.clone().add(7, "days");

            if (endDate.isBefore(minEndDate, "day")) {
              end = minEndDate.format("YYYY/MM/DD");
              vm.$toasted.error(vm.$t('texts.video.min_date_range_step_2'))
            }
            instance.setDate([start, end]);
          }

          if (end != undefined) {
            jQuery(".flatpickr-input").val(start + " ~ " + end);
            vm.video.start_at = moment(start).format("YYYY-MM-DD");
            vm.video.end_at = moment(end).format("YYYY-MM-DD");
          } else {
            vm.video.start_at = null;
            vm.video.end_at = null;
          }
        },
        onReady() {
          let startAt = vm.video.start_at;
          let endAt = vm.video.end_at;
          let startFormat = moment(startAt).format("YYYY/MM/DD");
          let endFormat = moment(endAt).format("YYYY/MM/DD");
          if (startAt != null || endAt != null) {
            jQuery(".flatpickr-input").val(startFormat + " ~ " + endFormat);
          }
        },
        onOpen: (selectedDates, dateStr, instance) => {
          vm.getHolidays(instance);
        },
        onYearChange: (selectedDates, dateStr, instance) => {
          vm.getHolidays(instance);
        },
      });
    },
    initFlatpickrTime() {
      let vm = this;
      this.flatpickrInstanceTime = flatpickr("#timepicker", {
        static: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        allowInput: true,
        altInput: true,
        altFormat: "H:i",
        defaultDate: vm.video.start_time_at,
        time_24hr: true,
        clickOpens: false,
        altInputClass: "input-time form-control",
        onChange(selectedDates, dateStr, instance) {
          if (dateStr != "") {
            vm.video.start_time_at = dateStr;
          } else {
            vm.video.start_time_at = "00:00";
            instance.setDate("00:00");
          }
        },
      });
    },
    handleDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      if (files.length) {
        this.$refs.fileUpload.files = files;
        this.uploadFile({ target: this.$refs.fileUpload });
      }
    },
    uploadFile(event) {
      let vm = this;
      let files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        return;
      }
      vm.isVideoFile = true;
      let file = files[0];
      vm.video_file = file;
      vm.fileName = file.name;
      vm.video.video_file_text = file.name;
      vm.video.video_url = URL.createObjectURL(file);
      vm.updateVideoCapture()
    },
    clearFile() {
      this.video_file = null;
      this.fileName = "動画ファイルをドラッグ";
      this.video.video_file_text = "";
      this.video.video_url = "";
      this.isVideoFile = false;
      jQuery("#file-upload").val("");
    },

    handleNextPage() {
      let vm = this;
      if (this.video.video_url_type === 2) {
        this.video.video_url = this.youtubeURL.slice(32);
      }
      this.submitted = true;
      this.hasMessageEmptyQuestionCDAI = false;
      this.hasMessageEmptyCommentScene = false;
      let hasError = false;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        hasError = true;
      }

      // check if it has question CDAI checked
      if (!this.checkHasQuestionCDAIChecked()) {
        this.hasMessageEmptyQuestionCDAI = true;
        hasError = true;
      }

      // check if it not has comment scene
      if (
        this.video.deliver_flg != 3 &&
        this.videoCaptureData[this.video.video_url_type].duration > 0 &&
        this.videoCaptureData[this.video.video_url_type].video_comment_scene.length == 0 &&
        this.video.is_rs_cdai == commentDiscoveryStatus.ON.value
      ) {
        this.hasMessageEmptyCommentScene = true;
        hasError = true;
      }

      // check if it has setting segment error
      if (
        this.hasSettingErrorSegment ||
        this.requireOnSettingSegment ||
        this.isSettingErrorSegmentAttribute
      ) {
        hasError = true;
      }

      if (hasError) {
        return;
      }

      if (this.video.has_custom_segment == CUSTOM_SEGMENT_OFF_VALUE) {
        this.video.feasibility_ids = [];
      }

      if (
        this.video.deliver_period_type == 2 &&
        this.video.feasibility_ids.length == 0 &&
        this.video.is_admin_change == manualTrafficStatus.OFF.value &&
        (
          this.video.has_custom_segment == CUSTOM_SEGMENT_OFF_VALUE ||
          this.video.has_custom_segment == CUSTOM_SEGMENT_ON_VALUE && this.groupsSegment[0].length == 0
        )
      ) {
        this.syncRsSegment([], function (isPass) {
          if (!isPass) {
            vm.requireOnSettingSegment = true;
          } else {
            vm.nextPage()
          }
        })
      } else {
        vm.nextPage()
      }
    },

    nextPage() {
      router.push({
        name: "video-check",
        params: { video_file: this.video.video_url_type === 1 ? this.video_file : undefined },
      });
      if (this.selectedLabels.length) this.video.label_ids = this.selectedLabels.toString()
      else this.video.label_ids = ""

      let interactionBtnParams = {};
      let interactionButtonValue = {};
      let interactionButtonType = this.video.deliver_flg != 3 ? this.interactionTypes : this.interactionTypeVerifyOne;

      if (this.video.deliver_period_type === this.listStep.step1.id) {
        interactionButtonType = this.interactionTypesStep1
      }

      if (this.video.deliver_period_type != this.listStep.step3.id) {
        Object.keys(interactionButtonType).forEach(item => {
          if (this.video.deliver_period_type === this.listStep.step2.id) {
            if (this.video.deliver_flg != 3 && interactionButtonType[item].index == this.video.interaction_btn_1_index) {
              interactionButtonValue = interactionButtonType[item].value;
            } else if (this.video.deliver_flg == 3 && interactionButtonType[item].index == this.video.interaction_btn_2_index) {
              interactionButtonValue = interactionButtonType[item].value;
            }
          } else {
            if (interactionButtonType[item].index == this.video.interaction_btn_3_index) {
              interactionButtonValue = interactionButtonType[item].value;
            }
          }
        })
        if (this.video.deliver_flg != 3) {
          interactionBtnParams = {
            interaction_btn_1_text: interactionButtonValue.interaction_btn_1_text,
            interaction_btn_1_icon_id: interactionButtonValue.interaction_btn_1_icon_id,
            interaction_btn_1_explain: interactionButtonValue.interaction_btn_1_explain,
            interaction_btn_1: this.getIconAndColor(interactionButtonValue.interaction_btn_1_icon_id),
            interaction_btn_2_text: interactionButtonValue.interaction_btn_2_text,
            interaction_btn_2_icon_id: interactionButtonValue.interaction_btn_2_icon_id,
            interaction_btn_2_explain: interactionButtonValue.interaction_btn_2_explain,
            interaction_btn_2: this.getIconAndColor(interactionButtonValue.interaction_btn_2_icon_id),
            ...(this.video.deliver_period_type == this.listStep.step1.id ? {
              interaction_btn_3_text: interactionButtonValue.interaction_btn_3_text,
              interaction_btn_3_icon_id: interactionButtonValue.interaction_btn_3_icon_id,
              interaction_btn_3_explain: interactionButtonValue.interaction_btn_3_explain,
              interaction_btn_3: this.getIconAndColor(interactionButtonValue.interaction_btn_3_icon_id),
            } : {}),
          }
        } else {
          interactionBtnParams = {
            interaction_btn_1_text: interactionButtonValue.interaction_btn_1_text,
            interaction_btn_1_icon_id: interactionButtonValue.interaction_btn_1_icon_id,
            interaction_btn_1_explain: interactionButtonValue.interaction_btn_1_explain,
            interaction_btn_1: this.getIconAndColor(interactionButtonValue.interaction_btn_1_icon_id),
            interaction_btn_2_text: '',
            interaction_btn_2_icon_id: '',
            interaction_btn_2_explain: '',
            interaction_btn_2: {}
          }
        }
      }
      if (this.settingOptionDone && this.buyerSelected.plan !== this.planTypes.FREE_PLAN.buyer_plan) {
        this.video.screening = this.formatScreeningParams()
        localStorage.setItem("screening_option", JSON.stringify(this.questions))
      } else {
        delete this.video['screening']
        localStorage.removeItem("screening_option")
      }

      // remove empty keywords
      this.video.twitter_keywords = this.video.twitter_keywords.filter(item => item !== '')

      localStorage.setItem("video_register", JSON.stringify({...this.video, ...interactionBtnParams}));
      localStorage.setItem("buyer_id", this.buyerId);
      localStorage.setItem("layers", JSON.stringify(this.formatToAttributeLayer()));
      localStorage.setItem("interactionTypesCustom", JSON.stringify(this.interactionTypes));
      localStorage.setItem("interactionTypesCustomStep1", JSON.stringify(this.interactionTypesStep1));
      localStorage.setItem("interactionTypeVerifyOneCustom", JSON.stringify(this.interactionTypeVerifyOne));
      if (this.video.has_custom_segment) {
        localStorage.setItem("groupsSegment", JSON.stringify(this.groupsSegment));
        localStorage.setItem('segmentSelected', JSON.stringify(this.segmentSelected))
      } else {
        this.groupsSegment = [[]]
        this.segmentSelected = []
        localStorage.removeItem("groupsSegment")
        localStorage.removeItem("segmentSelected")
      }

      localStorage.setItem("segmentSettingModel", JSON.stringify(this.segmentSettingModel));
      localStorage.setItem("previousVideoId", this.previousVideoId);
      localStorage.setItem("intentionList", JSON.stringify(this.intentionList));
      localStorage.setItem("intentionData", JSON.stringify(this.intentionData));
      localStorage.setItem("videoCaptureData", JSON.stringify(this.videoCaptureData));
      localStorage.setItem("checkedQuestionCDAI", JSON.stringify(this.checkedQuestionCDAI));
    },
    getIconAndColor(id) {
      let i = 1, iconAndColor = {}
      while(i <= 5) {
        if (this.icons[i]) {
          iconAndColor = this.icons[i].filter(item => item.id == id)
          if (iconAndColor.length) break
        }
        i++
      }
      return {
        'btn_icon_url': iconAndColor.length ? iconAndColor[0].image_url : '',
        'color': i
      }
    },
    getIconsList(data) {
      this.icons = data
    },
    formatToAttributeLayer() {
      let id = 1;
      let parent = {
        id: 1,
        operator: "OR",
        sort: 1,
        attribute_value_ids: [],
      };
      let layers = [parent];
      this.groupsSegment.forEach((groupAttributes, index) => {
        let attributeIdsCategory = {};
        let idGroup = ++id;
        layers.push({
          id: idGroup,
          operator: "AND",
          parent_id: parent.id,
          sort: index+1,
          attribute_value_ids: [],
        });
        let sort = 1
        groupAttributes.forEach((attribute) => {
          let listAttrValueIds = attribute.list.map((e) => e.id);
          if (attributeIdsCategory[attribute.category]) {
            attributeIdsCategory[attribute.category].attribute_value_ids = [...attributeIdsCategory[attribute.category].attribute_value_ids, ...listAttrValueIds]
          } else {
            attributeIdsCategory[attribute.category] = {
              parent_id: idGroup,
              id: ++id,
              sort: sort++,
              operator: "OR",
              attribute_value_ids: listAttrValueIds
            };
          }
        });
        layers = [...layers, ...Object.values(attributeIdsCategory)]
      });
      return layers;
    },
    formatScreeningParams() {
      let screening = []
      this.questions.map((q,k) => {
        let answers = [], depend_on_questions = '', depend_on_answers = ''
        q.options.map((a, i) => {
          answers.push({
            "code": i + 1,
            "text": a.value
          })
        })
        let question = {
          "question_code": k + 1,
          "text": q.value,
          "answers": answers
        }
        if (k > 0) {
          Object.values(q.isChecked).map((val, ix) => {
            if (val) depend_on_questions += ix + 1
          })
          question = {...question, ...{"depend_on_questions": depend_on_questions}}
          if (depend_on_questions) {
            this.questions[k - 1].options.map(prevA => {
              if (prevA.isChecked) depend_on_answers += '1'
              else depend_on_answers += '0'
            })
            if (q.q1q2Options && depend_on_questions == '12') {
              depend_on_answers = ''
              this.questions[1].options.map((q2, ix2) => {
                this.questions[0].options.map(q1 => {
                  if (q1.isChecked) {
                    if (q.q1q2Options[q1.value + 'x' + q2.value]) depend_on_answers += '1'
                    else depend_on_answers += '0'
                  }
                })
                if (ix2 < this.questions[1].options.length - 1 && depend_on_answers) depend_on_answers += ','
              })
            } else if (q.q1Options && depend_on_questions == '1') {
              depend_on_answers = ''
              this.questions[0].options.map(q1 => {
                if (q.q1Options[q1.value]) depend_on_answers += '1'
                else depend_on_answers += '0'
              })
            }
            question = {...question, ...{"depend_on_answers": depend_on_answers}}
          } else {
            question = {...question, ...{"depend_on_answers": ""}}
          }
        } else {
          question = {...question, ...{"depend_on_questions": ""}}
          question = {...question, ...{"depend_on_answers": ""}}
        }
        screening.push(question)
      })
      return screening
    },
    initData() {
      this.getListLabel();
      this.getListChannelYoutube();
      this.initPlan();
      this.initListIntention();
      this.getListBuyerQuestion();
    },
    getVideo(base64, video_file_name) {
      let base64Parts = base64.split(",");
      let fileFormat = base64Parts[0].split(";")[1];
      let fileContent = base64Parts[1];
      let file = new File([fileContent], video_file_name, {
        type: fileFormat,
      });
      this.video_file = file;
    },
    configNotDeleteInputUrlYoutube() {
      let defaultText = "https://www.youtube.com/watch?v=";
      var test = document.querySelector(".input-youtube-url");
      var val = defaultText;
      this.listen("keydown", test, replaceVal);
      this.listen("keyup", test, replaceVal);
      this.listen("click", test, replaceVal);
      jQuery(window).on("click", replaceVal);

      function replaceVal() {
        var tempVal = test.value;
        if (tempVal.indexOf(val) === -1) {
          tempVal = val;
          test.value = val;
        }
      }
    },
    configNotDeleteInputValue() {
      let defaultText = "https://www.youtube.com/watch?v=";
      var test = document.querySelector(".input-channel-video");
      var val = defaultText;
      this.listen("keydown", test, replaceVal);
      this.listen("keyup", test, replaceVal);
      this.listen("click", test, replaceVal);
      jQuery(window).on("click", replaceVal);
      function replaceVal() {
        var tempVal = test.value;
        if (tempVal.indexOf(val) === -1) {
          tempVal = val;
          test.value = val;
        }
      }
    },
    listen(event, elem, func) {
      if (elem.addEventListener) {
        elem.addEventListener(event, func, false);
      } else if (elem.attachEvent) {
        elem.attachEvent("on" + event, func);
      }
    },
    loadListen() {
      this.listen("load", window, this.configNotDeleteInputUrlYoutube());
      this.listen("load", window, this.configNotDeleteInputValue());
    },
    // get all JP holidays
    getHolidays(instance) {
      let vm = this;
      let url = calendarEventsUrl(instance.currentYear);
      this.$http2.getExternalAPI(
        url,
        (response) => {
          let items = response.items;
          vm.holidays = items.map((item) => {
            return item.start.date;
          });
          instance.redraw();
        },
        (err) => {
          console.log("Fetch Error :", err);
        }
      );
    },
    // drag and drop file upload
    handleDropFile() {
      let vm = this;
      jQuery("#dropbox-file-upload").on("dragover", function (e) {
        e.preventDefault();
        e.stopPropagation();
      });
      jQuery("#dropbox-file-upload").on("dragenter", function (e) {
        e.preventDefault();
        e.stopPropagation();
      });
      jQuery("#dropbox-file-upload").on("drop", function (e) {
        if (e.originalEvent.dataTransfer) {
          if (e.originalEvent.dataTransfer.files.length) {
            e.preventDefault();
            e.stopPropagation();
            let file = e.originalEvent.dataTransfer.files[0];
            vm.isVideoFile = true;
            vm.video_file = file;
            vm.fileName = file.name;
            vm.video.video_file_text = file.name;
            vm.video.video_url = URL.createObjectURL(file);
          }
        }
      });
    },
    // get data api
    getListBuyer() {
      let url = this.$router.resolve({
        name: "api.buyers",
      }).href;
      let params = {
        approve_status: 1,
        status: 1,
        sort: "id",
        order: "asc",
      };
      this.$http2.get(url, params, (data) => {
        this.clients = data.items;
        this.clientsDefault = JSON.parse(JSON.stringify(data.items))
        if (!this.isCacheData) {
          if (!this.$store.getters.buyerSelected){
            this.buyerId = data.items[0].id;
            this.buyerSelected = data.items[0];
          } else {
            this.buyerId = this.$store.getters.buyerSelected;
            this.isBuyerId = true;
          }
        }
        this.initData();
      });
    },
    getListCountry() {
      let url = this.$router.resolve({
        name: "api.countries",
      }).href;

      this.$http2.get(url, {}, (data) => {
        this.countries = data.items;
        if (!this.isCacheData) {
          this.video.country_id = data.items[0].id;
        }
      });
    },
    getListChannelYoutube() {
      let url = this.$router.resolve({
        name: "api.youtube.analytics",
      }).href;
      let params = {
        buyer_id: this.buyerId,
        sort: "id",
        order: "asc",
        limit: 1000,
      };
      this.$http2.get(url, params, (data) => {
        this.youtubeChannels = data.items;
      });
    },

    getListLabel() {
      let video = JSON.parse(localStorage.getItem("video_register"))
      listLabels((labels) => {
        this.selectedLabels = []
        this.labels = labels
        if (video && video.label_ids) {
          let label_ids = video.label_ids.split(',').map(Number)
          this.labels.map(item => {
            if (label_ids.includes(item.id)) this.selectedLabels.push(item.id)
          })
        }
      }, this.buyerId)
    },
    customInteractionButton(data) {
      EventBus.$emit("resetFormCustom");
      if (this.video.deliver_period_type === this.listStep.step2.id) {
        if (this.video.deliver_flg != 3) {
          this.interactionTypes['CUSTOM'] = {
            'index': 4,
            'text' : data.interaction_btn_1_text+"/"+data.interaction_btn_2_text,
            'value' : {
              'interaction_btn_1_explain' : data.interaction_btn_1_explain,
              'interaction_btn_1_icon_id' : data.interaction_btn_1_icon_id,
              'interaction_btn_1_text' : data.interaction_btn_1_text,
              'interaction_btn_2_explain' : data.interaction_btn_2_explain,
              'interaction_btn_2_icon_id' : data.interaction_btn_2_icon_id,
              'interaction_btn_2_text' : data.interaction_btn_2_text,
            }
          }
          this.video.interaction_btn_1_index = 4
        } else {
          this.interactionTypeVerifyOne['CUSTOM'] = {
            'index' : 4,
            'text' : data.interaction_btn_1_text,
            'value' : {
              'interaction_btn_1_explain' : data.interaction_btn_1_explain,
              'interaction_btn_1_icon_id' : data.interaction_btn_1_icon_id,
              'interaction_btn_1_text' : data.interaction_btn_1_text,
            }
          }
          this.video.interaction_btn_2_index = 4
        }
      } else {
        this.interactionTypesStep1['CUSTOM'] = {
          'index': 2,
          'text' : data.interaction_btn_1_text + "/" + data.interaction_btn_2_text + "/" + data.interaction_btn_3_text,
          'value' : {
            'interaction_btn_1_explain' : data.interaction_btn_1_explain,
            'interaction_btn_1_icon_id' : data.interaction_btn_1_icon_id,
            'interaction_btn_1_text' : data.interaction_btn_1_text,
            'interaction_btn_2_explain' : data.interaction_btn_2_explain,
            'interaction_btn_2_icon_id' : data.interaction_btn_2_icon_id,
            'interaction_btn_2_text' : data.interaction_btn_2_text,
            'interaction_btn_3_explain' : data.interaction_btn_3_explain,
            'interaction_btn_3_icon_id' : data.interaction_btn_3_icon_id,
            'interaction_btn_3_text' : data.interaction_btn_3_text,
          }
        }
        this.video.interaction_btn_3_index = 2
      }
    },
    selectQuestionToAddOption(id) {
      this.addOptionInfo = this.questions[id - 1]
    },
    q1OptionsSetting() {
      let q1Options = {}
      this.questions[0].options.map(q1 => {
        q1Options[q1.value] = false
      })
      return q1Options
    },
    q1q2OptionsSetting(isUpdate = false) {
      let q1q2Options = {}
      this.questions[0].options.map(q1 => {
        this.questions[1].options.map(q2 => {
          q1q2Options[q1.value + 'x' + q2.value] = false
        })
      })

      if (isUpdate) {
        let currentOptions = this.questions[2]?.q1q2Options
        if (currentOptions) {
          Object.keys(currentOptions).map(function (key) {
            if (Object.prototype.hasOwnProperty.call(q1q2Options, key)) {
              q1q2Options[key] = currentOptions[key]
            }
          })
        }
      }

      return q1q2Options
    },
    addQuestion() {
      if (this.questions.length < 2) {
        this.questions.push({
          id: 2, name: 'Q2', value: '', isChecked: {'Q1': false}, options: []
        })
      } else {
        this.questions.push({
          id: 3, name: 'Q3', value: '', isChecked: {'Q1': false, 'Q2': false},
          q1Options: this.q1OptionsSetting(),
          q1q2Options: this.q1q2OptionsSetting(),
          options: []
        })
      }
    },
    removeQuestion(id) {
      this.questions.splice(id - 1, 1)
      if (id === 2) {
        let question = this.questions[id - 1]
        if (question) {
          this.questions[id - 1] = {
            id: 2, name: 'Q2', value: question.value, isChecked: {'Q1': question.isChecked['Q1']}, options: question.options
          }
          Object.entries(question.q1Options).forEach(([key, value]) => {
            this.questions[0].options.filter(item => {return item.value == key})[0].isChecked = value
          })
        }
      }
      this.questions = [...this.questions]
    },
    settingOption(val) {
      this.settingOptionDone = val
      this.prevQuestion = JSON.parse(JSON.stringify(this.questions))
    },
    closeModalScreeningOption() {
      this.showModalName = ''
      this.questions = JSON.parse(JSON.stringify(this.prevQuestion))
    },
    selectQuestionToEditOption(args) {
      this.questions[args.id - 1].options[args.idOption - 1].isEdit = true
    },
    editOption(args) {
      if (args.valueOption) {
        this.questions[args.id - 1].options[args.idOption - 1].value = args.valueOption
        this.questions[args.id - 1].options[args.idOption - 1].isEdit = false

        if (Object.prototype.hasOwnProperty.call(this.questions, 2) && this.questions[2]?.q1q2Options) {
          this.questions[2].q1q2Options = this.q1q2OptionsSetting(true)
        }
      }
      else this.removeOption(args)
    },
    removeOption(args) {
      this.questions[args.id - 1].options.splice(args.idOption - 1, 1)
      this.questions[args.id - 1].options.map((value, index) => {
        value.id = index + 1
      })
      if (args.id - 1 !== 2 && this.questions[2]) {
        this.questions[2].q1Options = this.q1OptionsSetting()
        this.questions[2].q1q2Options = this.q1q2OptionsSetting(true)
      }
    },
    resetOption(index) {
      this.questions[index].options.map(val => {
        val.isChecked = false
      })
      if (index == 1) {
        Object.keys(this.questions[2].q1Options).map(k => {
          this.questions[2].q1Options[k] = false
        })
        Object.keys(this.questions[2].q1q2Options).map(k => {
          this.questions[2].q1q2Options[k] = false
        })
      }
    },
    addAnswerToQuestion(args) {
      let len = this.questions[args.qId - 1].options.length
      this.questions[args.qId - 1].options.push({id: len + 1, value: args.valueOption, isChecked: false, isEdit: false})
    },
    initPlan() {
      let currentBuyer;
      if (this.buyerId) {
        this.buyerSelected = this.clients.find(item => item.id == this.buyerId)
      }
      if (this.buyerSelected && Object.keys(this.buyerSelected).length) {
        currentBuyer = JSON.parse(JSON.stringify(this.buyerSelected))
      } else {
        currentBuyer = this.clients.filter(data => data.id === this.buyerId)[0]
      }
      if (this.currentUser.role !== 'admin') {
        currentBuyer = this.currentUser
        this.buyerSelected = JSON.parse(JSON.stringify(currentBuyer))
      }

      this.stepSubmitted = this.video.deliver_period_type != null;
    },
    changeStep(step) {
      if (step != stepListInfo.step2.id && this.buyerSelected.plan != planTypes.ADVANCE_PLAN.buyer_plan) {
        return false
      }
      if (this.video.deliver_period_type == step) {
        this.$set(this.video, "deliver_period_type", null)
      } else {
        this.$set(this.video, "deliver_period_type", step)
        if (step == this.listStep.step1.id) {
          this.$set(this.video, "deliver_flg", 1)
        }
      }
    },
    submitStep() {
      history.pushState({}, null, '/add');
      this.stepSubmitted = true
    },

    addKeyword() {
      this.video.twitter_keywords.push('')
    },

    refactorVideoKeyword() {
      let vm = this;
      const defaultKeywordData = ['','','','','']
      if (!Object.prototype.hasOwnProperty.call(vm.video, 'twitter_keywords')) {
        vm.video.twitter_keywords = defaultKeywordData
      } else if (vm.video.twitter_keywords.length && vm.video.twitter_keywords.length < defaultKeywordData.length) {
        defaultKeywordData.forEach(function (item, index) {
          if (!vm.video.twitter_keywords[index]) {
            vm.video.twitter_keywords[index] = item
          }
        })
      }
    },

    settingAndOrSegment() {
      let vm = this;
      // only for step 2
      if (
          this.video.deliver_period_type != this.listStep.step2.id ||
          this.segmentSelected.length && this.checkRsFlagSegment(this.segmentSelected)
      ) {
        vm.toggleModal("and-or")
        return false
      }
      // check selected segment ready for sync
      if (this.checkHasErrorSelectAttribute()) return false
      if (
        this.video.feasibility_ids.length &&
        this.compareStringSegments(this.stringSegmentSelected, this.video.listAttributeIdsRs)
      ) {
        vm.toggleModal("and-or")
      } else {
        this.syncRsSegment(this.segmentSelected, function (result) {
          if (result) {
            vm.toggleModal("and-or")
          }
        })
      }
    },

    checkRsFlagSegment(segmentData) {
      let checkFlag = false;
      let vm = this;
      segmentData.forEach(function (item) {
        let attribute = vm.attributes.find(function (item2) {
          return item2.id == item.id
        })
        if (attribute && attribute.rs_flg == 1) {
          checkFlag = true
        }
      })

      return checkFlag
    },

    syncRsSegment(segmentData = [], callback = () => {}) {
      // disable sync rs segment when is_admin_change is ON
      if (this.video.is_admin_change == manualTrafficStatus.ON.value) {
        callback(true)
        return false
      }
      // if it has rs_flg = 1 return
      if (segmentData.length && this.checkRsFlagSegment(segmentData)) {
        callback(true)
        return false
      }
      let vm = this;
      let list_id_string = listSegmentAttributeIdDefault.join(',')
      if (segmentData.length) {
        let listId = []
        segmentData.forEach(function (item) {
          if (item.list && item.list.length) {
            item.list.forEach(function (item2) {
              listId.push(item2.id)
            })
          }
        })
        list_id_string = listId.join(',')
      }
      vm.is_fullscreen_loading = true
      getRsFeasibilityApiId(list_id_string, function (response) {
        vm.is_fullscreen_loading = false
        if (response.rs_feasibility_api_id) {
          vm.getFeasibilityIds(response.rs_feasibility_api_id, function (feasibility_ids, status) {
            if (feasibility_ids && feasibility_ids.length) {
              feasibility_ids = feasibility_ids.split(',')
              vm.video.feasibility_ids = feasibility_ids
              vm.video.listAttributeIdsRs = list_id_string
              callback(true)
            } else if (status == rsSyncStatus.error) {
              vm.video.feasibility_ids = []
              vm.video.listAttributeIdsRs = ''
              callback(false)
              if (segmentData.length) {
                vm.isSettingSegmentError = true
              } else {
                vm.requireOnSettingSegment = true
              }
            } else {
              vm.video.feasibility_ids = []
              vm.video.listAttributeIdsRs = ''
              vm.isSettingSegmentError = false
              vm.requireOnSettingSegment = false
              callback(true)
            }
          }, function () {
            vm.video.feasibility_ids = []
            vm.video.listAttributeIdsRs = ''
            callback(false)
          })
        }
      }, function () {
        vm.is_fullscreen_loading = false
        vm.video.feasibility_ids = []
        vm.video.listAttributeIdsRs = ''
        callback(false)
      })
    },

    getFeasibilityIds(rs_feasibility_api_id, successCallback, errorCallback) {
      let vm = this
      vm.is_fullscreen_loading = true
      getRsFeasibilityIds(rs_feasibility_api_id, function (response) {
        // status = 0 => not complete, try again
        if (response.Status == 0) {
          setTimeout(function () {
            vm.getFeasibilityIds(rs_feasibility_api_id, successCallback, errorCallback)
          }, 15000)
        } else if (response.Status == rsSyncStatus.completed) {
          vm.is_fullscreen_loading = false
          successCallback(response.feasibility_ids, rsSyncStatus.completed);
        } else if (response.Status == rsSyncStatus.error) {
          vm.is_fullscreen_loading = false
          successCallback(null, rsSyncStatus.error)
        } else if (response.Status == rsSyncStatus.not_available) {
          vm.is_fullscreen_loading = false
          successCallback(null, rsSyncStatus.not_available)
        }
      }, function (error) {
        errorCallback(error)
      })
    },

    compareStringSegments(string1, string2) {
      const string1Numbers = string1.split(',');
      const string2Numbers = string2.split(',');
      string1Numbers.sort();
      string2Numbers.sort();
      return JSON.stringify(string1Numbers) === JSON.stringify(string2Numbers);
    },

    initListIntention() {
      getListIntention().then(res => {
        if (res.items) {
          this.intentionList = res.items
          // add index for sort
          this.intentionList.map((item, index) => {
            item.index = index
          })
        }
      })
    },

    getAdminChangeStatus() {
      let data = {
        is_disabled: false,
        value: manualTrafficStatus.OFF.value
      }

      if (this.video.deliver_flg == verifyMethods.ONE_TYPE.value) {
        data.is_disabled = true
        data.value = manualTrafficStatus.ON.value
        return data
      }

      let is_disable = false
      const hasRsSegment = this.checkRsFlagSegment(this.segmentSelected)
      const segmentOn = this.video.has_custom_segment
      const hasScreening = this.settingOptionDone
      const hasCognitionSurvey = this.video.cognition_survey === IMPLEMENT_COGNITION_SURVEY
      let isCustomQuestionCDAI = false
      if (
        this.video.is_rs_cdai == commentDiscoveryStatus.ON.value &&
        (
          (
            this.video.deliver_flg == verifyMethods.ALTERNATELY_TWO_TYPES.value &&
            (this.hasCustomCDAIQuestion || this.checkedQuestionCDAI.default[this.video.deliver_flg].length < 2)
          ) ||
          (
            this.video.deliver_flg == verifyMethods.TWO_TYPES.value &&
            (this.hasCustomCDAIQuestion || !this.checkedQuestionCDAI.default[this.video.deliver_flg].length)
          )
        )
      ) {
        isCustomQuestionCDAI = true
      }

      const hasAnyTypeChange = hasScreening || hasCognitionSurvey
      if (
        (
          segmentOn &&
          (
            (!hasAnyTypeChange && !hasRsSegment && isCustomQuestionCDAI) ||
            ((hasAnyTypeChange && !hasRsSegment) || hasRsSegment)
          )
        ) ||
        (
          !segmentOn &&
          (
            (!hasAnyTypeChange && isCustomQuestionCDAI) || hasAnyTypeChange
          )
        )
      ) {
        is_disable = true
      }

      if (is_disable) {
        data.is_disabled = true
        data.value = manualTrafficStatus.ON.value
      } else if (this.video.forceOnAdminChangeStatus) {
        data.is_disabled = false
        data.value = manualTrafficStatus.ON.value
      }

      return data
    },
    onAdminChangeUpdate($event) {
      if ($event.target.value == manualTrafficStatus.ON.value) {
        this.video.forceOnAdminChangeStatus = true
        this.requireOnSettingSegment = false
      } else {
        this.video.forceOnAdminChangeStatus = false
      }
    },

    updateVideoCapture(is_update = false) {
      let vm = this;
      if (
        vm.video.deliver_period_type !== vm.listStep.step2.id ||
        vm.video.deliver_flg == verifyMethods.ONE_TYPE.value ||
        vm.isUploadingVideoCapture
      ) {
        return
      }

      // check went switch url type
      if (is_update && vm.videoCaptureData[vm.video.video_url_type].duration > 0) {
        return
      }

      if (vm.video.video_url_type === videoURLTypes.NORMAL.value) {
        if (!vm.video_file) return
        vm.isUploadingVideoCapture = true
        vm.uploadFileS3(function (file_url) {
          vm.videoFileUrl = file_url
          vm.getVideoCapture()
        })
      } else {
        const videoUrlCheck = this.youtubeURL.slice(32);
        const videoUrlValidations = {
          required,
          maxLength: maxLength(11),
          halfWidthExcludeXss,
        }

        const isVideoUrlValid = Object.keys(videoUrlValidations).every((validationKey) => {
          const validationFunction = videoUrlValidations[validationKey];
          return validationFunction(videoUrlCheck);
        });
        // check video url is valid and has 11 characters
        if (isVideoUrlValid && videoUrlCheck.length == 11) {
          vm.getVideoCapture()
        }
      }
    },

    getVideoCapture(callback = () => {}) {
      let vm = this
      vm.isUploadingVideoCapture = true
      vm.videoCaptureErrorMessage = null
      let params = {}
      if (this.video.video_url_type === videoURLTypes.YOUTUBE.value) {
        params.video_url = this.youtubeURL.slice(32);
      } else {
        params.video_file_url = this.videoFileUrl
      }
      postVideoCapture(params, function (response) {
        if (response.tmp_video_id) {
          vm.getVideoCaptureData(response.tmp_video_id, function (captureData) {
            vm.videoCaptureData[vm.video.video_url_type].duration = captureData.duration
            vm.videoCaptureData[vm.video.video_url_type].url = captureData.url
            vm.videoCaptureData[vm.video.video_url_type].video_tmp_id = response.tmp_video_id
            callback(true)
          }, function (errorMessage) {
            if (errorMessage) {
              vm.videoCaptureErrorMessage = errorMessage
            }
            vm.isUploadingVideoCapture = false
            vm.videoCaptureData = vm.videoCaptureDataDefault
            vm.clearFile()
            callback(false)
          })
        }
      }, function () {
        vm.clearFile()
        vm.isUploadingVideoCapture = false
        callback(false)
      })
    },

    getVideoCaptureData(video_tmp_file_id, successCallback, errorCallback) {
      let vm = this
      getVideoCaptureApi(video_tmp_file_id, function (response) {
        vm.isUploadingVideoCapture = false
        successCallback(response);
      }, function (error) {
        if (error.body.Status == 0) {
          if (vm.componentDestroyed) {
            return
          }
          setTimeout(function () {
            vm.getVideoCaptureData(video_tmp_file_id, successCallback, errorCallback)
          }, 30000)
        } else if (error.body.Status == 2 && error.body.Message) {
          vm.clearFile()
          errorCallback(error.body.Message)
        }
      })
    },

    checkedVideoCapture(second) {
      if (!this.videoCaptureData[this.video.video_url_type].video_comment_scene.includes(second)) {
        // if array has 10 items, alert message and return
        if (this.videoCaptureData[this.video.video_url_type].video_comment_scene.length >= 10) {
          this.$toasted.error(this.$t('messages.max_second_select_capture'))
          return
        }
        this.videoCaptureData[this.video.video_url_type].video_comment_scene.push(second)
      } else {
        this.videoCaptureData[this.video.video_url_type].video_comment_scene.splice(
            this.videoCaptureData[this.video.video_url_type].video_comment_scene.indexOf(second), 1
        )
      }
    },

    switchVideoCapture(second) {
      if (this.isReplacingVideoCapture == true) {
        return
      }
      this.secondReplaceVideoCapture = second
      let input = jQuery("#upload-video-capture")
      input.val('')
      input.trigger('click')
    },

    replaceCapture(event) {
      let vm = this;
      let files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        return;
      }

      let file = files[0];
      // check file type is not .jpg
      if (file.type !== 'image/jpeg') {
        vm.$toasted.error(vm.$t('messages.video_capture_only_jpg'))
        return
      }

      let params = {
        video_tmp_id: vm.videoCaptureData[vm.video.video_url_type].video_tmp_id,
        second: vm.secondReplaceVideoCapture,
        capture: vm.videoCaptureData[vm.video.video_url_type].url + vm.secondReplaceVideoCapture + '.jpg'
      }

      vm.isReplacingVideoCapture = true

      getCaptureUploadUrl(params, function (data) {
        if (data.upload_url) {
          vm.$http2.uploadFileS3(data.upload_url, file, () => {
            let imgElement = jQuery(`img[data-url-type="${ vm.videoCaptureData[vm.video.video_url_type].url }"][data-index="${ vm.secondReplaceVideoCapture }"]`)
            if (imgElement.length) {
              let url = imgElement.attr('src')
              url = url.replace(/\?t=\d+/, '')
              imgElement.attr('src', url + '?t=' + new Date().getTime())
              vm.isReplacingVideoCapture = false
            }
          });
        }
      }, function (error) {
        console.log(error)
      })
    },

    uploadFileS3(success, fail) {
      let url = this.$router.resolve({
        name: "api.videos.upload_url",
      }).href;
      this.$http2.get(
          url,
          {},
          (data) => {
            // let urlS3 = data.upload_url.split('?');
            let urlS3 = data.upload_url;
            this.$http2.uploadFileS3(urlS3, this.video_file, () => {
              success(data.file_name);
            });
          },
          () => {
            fail && fail();
          }
      );
    },

    getDefaultQuestionCDAI() {
      if (this.video.deliver_period_type != this.listStep.step2.id || this.video.deliver_flg == verifyMethods.ONE_TYPE.value) {
        return []
      }
      let interactionType
      Object.keys(this.interactionTypes).forEach(key => {
        if (this.interactionTypes[key].index == this.video.interaction_btn_1_index) {
          interactionType = this.interactionTypes[key]
        }
      })

      if (this.video.deliver_flg == verifyMethods.TWO_TYPES.value) {
        return [
          {
            text: interactionType.text + ": " +
                this.$t('texts.video.default_question_deliver_flg1',
                    {
                      btn_1: interactionType.value.interaction_btn_1_text,
                      btn_2: interactionType.value.interaction_btn_2_text,
                    }
                ),
          }
        ]
      } else {
        return [
          {
            text: interactionType.value.interaction_btn_1_text + ": " +
                this.$t('texts.video.default_question_deliver_flg2',
                    {
                      btn: interactionType.value.interaction_btn_1_text,
                    }
                ),
          },
          {
            text: interactionType.value.interaction_btn_2_text + ": " +
                this.$t('texts.video.default_question_deliver_flg2',
                    {
                      btn: interactionType.value.interaction_btn_2_text,
                    }
                ),
          }
        ]
      }
    },

    updateCheckedQuestionCDAI(type, index) {
      let checkedData = this.checkedQuestionCDAI[type]
      if (type === 'default') {
        checkedData = this.checkedQuestionCDAI[type][this.video.deliver_flg]
        if (checkedData.includes(index)) {
          checkedData.splice(checkedData.indexOf(index), 1)
        } else {
          checkedData.push(index)
        }
      } else {
        if (checkedData[index].text.replace(/\s+/g, '').length) {
          checkedData[index].is_checked = !checkedData[index].is_checked
        }
      }
    },

    addQuestionCDAI() {
      if (this.isDisableAddQuestionCDAI) {
        return
      }

      this.checkedQuestionCDAI.admin_custom.push(
          {
            text: '',
            is_checked: false,
          }
      )
    },

    checkEmptyQuestionCDAIAdmin(index) {
      let checkedData = this.checkedQuestionCDAI.admin_custom
      if (!checkedData[index].text.replace(/\s+/g, '').length) {
        checkedData[index].is_checked = false
      }
    },

    getListBuyerQuestion(is_update = false) {
      if (this.checkedQuestionCDAI.buyer_custom.length && !is_update) {
        return
      }
      let vm = this
      getVideoQuestion(vm.buyerId, function (questionData) {
        vm.checkedQuestionCDAI.buyer_custom = []
        if (questionData.data && questionData.data.length) {
          questionData.data.forEach(item => {
            const question = {
              text: item,
              is_checked: false,
            }
            vm.checkedQuestionCDAI.buyer_custom.push(question)
          })
        }
      })
    },

    checkHasQuestionCDAIChecked() {
      if (
        this.video.deliver_period_type != this.listStep.step2.id ||
        this.video.deliver_flg == verifyMethods.ONE_TYPE.value ||
        this.video.is_rs_cdai == commentDiscoveryStatus.OFF.value
      ) {
        return true
      }
      let checkedData = this.checkedQuestionCDAI
      let hasChecked = false
      Object.keys(checkedData).forEach(key => {
        if (key === 'admin_custom' || key === 'buyer_custom') {
          checkedData[key].forEach(item => {
            if (item.is_checked) {
              hasChecked = true
            }
          })
        } else {
          if (checkedData[key][this.video.deliver_flg].length) {
            hasChecked = true
          }
        }
      })

      return hasChecked
    },

    closeModal() {
      this.$refs.chatGptModal.visible()
    }
  },

  mounted() {
    this.loadListen();
    this.selectVideoUrlType();
    this.selectStatus();
    this.selectCognitionSurvey();
    this.checkYoutubeAnalytics();
    this.initFlatPickr();
    this.changeInputYoutubeChannelVideoId();
    this.handleDropFile();
    forceArrowDownSelect2();
    let interactionTypesCustom = JSON.parse(localStorage.getItem("interactionTypesCustom"));
    if (interactionTypesCustom) {
      this.interactionTypes = interactionTypesCustom
    }

    let interactionTypesCustomStep1 = JSON.parse(localStorage.getItem("interactionTypesCustomStep1"));
    if (interactionTypesCustomStep1) {
      this.interactionTypesStep1 = interactionTypesCustomStep1
    }

    let interactionTypeVerifyOneCustom = JSON.parse(localStorage.getItem("interactionTypeVerifyOneCustom"));
    if (interactionTypeVerifyOneCustom) {
      this.interactionTypeVerifyOne = interactionTypeVerifyOneCustom
    }
    let segmentSelected = JSON.parse(localStorage.getItem("segmentSelected"));
    if (segmentSelected) {
      this.segmentSelected = segmentSelected
    }
    let groupsSegment = JSON.parse(localStorage.getItem("groupsSegment"));
    if (groupsSegment) {
      this.groupsSegment = groupsSegment
    }
    let questions = JSON.parse(localStorage.getItem("screening_option"))
    if (questions) {
      this.questions = questions
      this.prevQuestion = JSON.parse(JSON.stringify(this.questions))
      this.settingOptionDone = true
    }

    let intentionData = JSON.parse(localStorage.getItem("intentionData"));
    if (intentionData) {
      this.intentionData = intentionData
    }

    let videoCaptureData = JSON.parse(localStorage.getItem("videoCaptureData"));
    if (videoCaptureData && this.video_file) {
      this.videoCaptureData = videoCaptureData
    }

    let checkedQuestionCDAI = JSON.parse(localStorage.getItem("checkedQuestionCDAI"));
    if (checkedQuestionCDAI) {
        this.checkedQuestionCDAI = checkedQuestionCDAI
    }

    let video = JSON.parse(localStorage.getItem("video_register"));
    if (!video) {
      let type = jQuery("input:radio[name=type]");
      let cognition_survey = jQuery("input:radio[name=cognition_survey]");
      let status = jQuery("input:radio[name=status]");
      if (type.is(":checked") === false) {
        type.filter(`[value=1]`).prop("checked", true);
      }
      if (cognition_survey.is(":checked") === false) {
        cognition_survey.filter(`[value=0]`).prop("checked", true);
      }
      if (status.is(":checked") === false) {
        status.filter(`[value=1]`).prop("checked", true);
      }
    } else {
      let vm = this;
      let type = jQuery("input:radio[name=type]");
      let cognition_survey = jQuery("input:radio[name=cognition_survey]");
      let status = jQuery("input:radio[name=status]");
      if (type.is(":checked") === false) {
        type.filter(`[value=${vm.video.video_url_type}]`).prop("checked", true);
      }
      if (cognition_survey.is(":checked") === false) {
        cognition_survey
          .filter(`[value=${vm.video.cognition_survey}]`)
          .prop("checked", true);
      }
      if (status.is(":checked") === false) {
        status.filter(`[value=${vm.video.status}]`).prop("checked", true);
      }
      if (vm.video.youtube_analytic_id) {
        jQuery("#youtube-analytics").prop("checked", true);
        vm.isYoutubeAnalytics = true;
      }

      this.stepSubmitted = video.deliver_period_type != null;

      let segmentSettingModel = JSON.parse(localStorage.getItem("segmentSettingModel"))
      if (segmentSettingModel) {
        this.segmentSettingModel = segmentSettingModel
      }

      let previousVideoId = JSON.parse(localStorage.getItem("previousVideoId"))
      if (previousVideoId) {
        this.previousVideoId = previousVideoId
      }

    }
    this.getListCategory();
  },
  created() {
    let video = JSON.parse(localStorage.getItem("video_register"));
    let buyer_id = localStorage.getItem("buyer_id");
    let currentUser = this.$store.getters.currentUser;
    this.currentUser = currentUser;

    if (video && buyer_id && !video.id) {
      this.video = video;
      this.video_file = this.$route.params.video_file;
      this.buyerId = buyer_id;
      this.isBuyerId = true;
      this.isCountry = true;
      this.isChannel = true;
      this.buyerId = parseInt(this.buyerId);
      this.video.country_id = parseInt(this.video.country_id);
      if (this.video.youtube_analytic_id) this.video.youtube_analytic_id = parseInt(this.video.youtube_analytic_id)
      this.isCacheData = true;
      if (video.video_url_type == 2) {
        this.youtubeURL = this.youtubeURL + video.video_url;
      }
      this.youtubeVideoChannelURL =
        this.youtubeVideoChannelURL + video.youtube_video_id;

      if (this.video_file) {
        this.isVideoFile = true;
        this.fileName = video.video_file_text;
      } else {
        this.clearFile();
      }

      this.refactorVideoKeyword()
    }

    if (currentUser && currentUser.role == "admin") {
      this.getListBuyer();
    } else {
      this.buyerId = this.currentUser.owner_id;
      this.buyerSelected = this.currentUser
      this.initData();
    }
    this.getListCountry();

    let vm = this;
    window.onpopstate = function() {
      if (vm.buyerSelected.plan == vm.planTypes.ADVANCE_PLAN.buyer_plan && vm.stepSubmitted) {
        vm.stepSubmitted = false
      } else if (vm.previousPath) {
        window.location.href = vm.previousPath
      } else {
        vm.stepSubmitted = false
        vm.$set(vm.video, "deliver_period_type", null)
      }
    };
    EventBus.$on("toggleModal", this.toggleModal)
    EventBus.$on("customInteractionButton", this.customInteractionButton)
    EventBus.$on("addOption", this.selectQuestionToAddOption)
    EventBus.$on("editOption", this.editOption)
    EventBus.$on("enabledEditOption", this.selectQuestionToEditOption)
    EventBus.$on("removeOption", this.removeOption)
  },
  destroyed() {
    EventBus.$off("toggleModal", this.toggleModal)
    EventBus.$off("customInteractionButton", this.customInteractionButton)
    EventBus.$off("addOption", this.selectQuestionToAddOption)
    EventBus.$off("editOption", this.editOption)
    EventBus.$off("enabledEditOption", this.selectQuestionToEditOption)
    EventBus.$off("removeOption", this.removeOption)
    this.componentDestroyed = true
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables/_colors.scss";
@import "@/styles/_add.scss";

.title-page {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 32px;
}

.mb-3 {
  margin-bottom: 12px;
}

.datetimepicker:disabled {
  background-color: #edf2f9;
  opacity: 1;
}

.form-group {
  &.form-group-label {
    label {
      margin-bottom: 0;
    }
  }
  .input-tag-font {
    font-family: 'Avenir Next', 'Noto Sans JP', sans-serif;
  }

  .group-tag-list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0;
    border: none;

    &.group-keyword-list {
      align-items: center;

      .tag {
        height: 53px;
        width: 53px;
        border: none;
      }
    }

    .tag {
      margin-right: 8px;
      margin-top: 8px;
      height: 36px;
    }

    .btn-plus {
      display: flex;
      background-color: $light-green;
      cursor: pointer;
      padding: 8px 9px;
    }
  }

  .group-keyword-list-input {
    margin-right: 10px;
    width: 12%;
    margin-top: 8px;

    &.question-admin-cdai {
      width: 440px;
    }
  }
}

.edit-segment-link {
  display: flex;
  align-items: center;
  color: #49ab94;
  font-size: 12px;
  margin-top: 8px;
  cursor: pointer;
  .icon-arrow-right {
    margin-left: 2px;
  }
}
.wrap-chatgpt {

  label {
    color : #49ab94 !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 22px !important;
    margin-bottom: 0;
  }
}

.chat-gpt {
  .modal-content {
    width: 670px;
    height: 456px;
    padding: 0;
    position: relative;
    border-radius: 10px;

    .header {
      position: absolute;
      right: 0;
      top: -42px;
    }

    .body {
      padding: 40px;
      color: rgba(51, 51, 51, 1);

      h2 {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 51.2px;
        margin: 0;
      }

      h3 {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

.text-link-setting-interaction-button{
  color : #49ab94;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
}

.verification-methods-description{
  font-style: normal;
  font-size: 10px;
  line-height: 16px;
  color: #999999;
}

.describe-panel {
  margin-top: 8px;
  color: $middle-black;
  @include font(s);
}

.describe-segment {
  margin-top: 8px;
  color: $red;
  font-size: 12px;
  line-height: 19px;
}

.screening-options {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #333333;
    margin-top: 0;
    margin-bottom: 8px;
  }

  button {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    font-family: 'Avenir Next', 'Noto Sans JP', sans-serif;
    line-height: 16px;
    color: #49AB94;
    padding: 5px 11px;
    background-color: #FFFFFF;
    border: 1px solid #49AB94;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }

  .screening-options-done {
    color: #FFFFFF;
    background-color: #49AB94;
  }
}

.preview-group-segment {
  &.list-attribute {
    padding: 10px 0;
    background-color: $light-green;
    border-radius: 8px;
    height: 294px;
    display: flex;
    flex-direction: column;
    position: relative;

    .btn-delete {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      @include font(s);
      color: $green;
    }

    .attributes {
      margin: 0;
      font-weight: bold;
      direction: rtl;
      overflow-y: auto;

      .list-item {
        border-bottom: 1px solid $middle-black;

        .title-item {
          color: #333333;
          font-size: 14px;
          line-height: 24px;
        }

        &:first-child {
          padding-top: 10px;
        }

        &:last-child {
          padding-bottom: 10px;
          border: none;
        }

        .describe-item-selected {
          .att-selected {
            &:last-child {
              margin-bottom: 0;
            }

            .name {
              @include font(m);
              color: $middle-black;
              margin-bottom: 4px;
              font-weight: bold;
            }

            .value {
              @include font(s);
              color: $black;
              display: inline-flex;
              flex-wrap: wrap;
              flex-direction: row-reverse;
              justify-content: left;
              gap: 12px;

              span {
                padding: 6px 12px;
                margin-right: 4px;
                border: 1px solid $black;
                border-radius: 8px;
                direction: ltr;
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $black;
      }
    }
  }
  &.error {
    background-color: $light-red;
    border: 1px solid $red;
  }
}

.radio-disabled {
  opacity: 0.5;
}

::v-deep .flatpickr-days {
  width: auto !important;
}

::v-deep .flatpickr-wrapper {
  display: block !important;
}

::v-deep .tooltip-content {
  transform: translate(3px, 4px);
  width: 0;
  height: 0;
  margin: 0 auto;
}

.intention-content {
  .select-intention {
    margin-bottom: 20px;
  }
}

.btn-plus-intention {
  cursor: pointer;
  font-size: 10px;
  line-height: 16px;
  color: #49AB94;
  padding: 5px 11px;
  border-radius: 8px;
  border: 1px solid #49AB94;
  font-weight: 700;
  font-family: "Noto Sans JP";
  display: inline-block;
  user-select: none;

  &.disabled {
    opacity: 0.5;
  }
}

.btn-plus-intention:hover {
  background-color: #49AB94;
  color: #FFFFFF;
}

.intention-item-checkbox {
  margin-right: 20px;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;

  .icon-checkbox {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .intention-item-name {
    font-size: 12px;
    line-height: 24px;
    color: #333333;
    margin-top: 4px;
  }
}

.default-question-cdai {
  .question-item {
    display: flex;
  }
}

.list-question-cdai-admin{
  .question-item{
    .form-group{
        position: relative;
        margin-bottom: 0;
        .error{
          position: absolute;
          left: 0;
        }
    }
  }
}
.question-item-error{
  margin-bottom: 30px;
}

.list-image-capture {
  background: $light-green;
  padding: 30px 30px 10px;
  margin-top: 10px;

  &.error {
    background: $light-red;
    border: 1px solid $red;
  }

  .capture-label {
    font-family: Noto Sans JP;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .count-image-capture {
    font-family: Noto Sans JP;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $green;
  }

  .list-image-content {
    max-height: 510px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 20px;
    padding-bottom: 20px;

    .image-capture {
      width: 13%;
      .image-capture-item {
        overflow: hidden;
        position: relative;
        display: inline-flex;
        border-radius: 8px;

        .image-wrapper {
          background: black;
          display: inline-flex;
          border-radius: 10px;

          img {
            object-fit: contain;
            width: 100%;
            aspect-ratio: 295/164;

            &.loading {
              opacity: 0.5;
            }
          }
        }

        .icon-switch-capture {
          width: 24px;
          height: 24px;
          position: absolute;
          top: 4px;
          right: 4px;
          cursor: pointer;
          z-index: 1;
          background-image: url('/images/svg/icon_switch_capture.svg');
        }

        &-checkbox {
          margin-top: 10px;
          display: flex;
          align-items: center;
          .icon-checkbox {
            display: inline-block;
          }
          .second-text {
            color: $green;
            font-family: Noto Sans JP;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-left: 10px;
            user-select: none;
          }
        }
      }
    }
  }
}

.input-disabled {
  background-color: $light-black !important;
}

.uploading-video-capture {
  display: block;
  margin: 0 auto;
}

.datetime-content {
  display: flex;
  gap: 20px;
}

::v-deep {
  .input-time {
    width: 45px;
    text-align: center;
  }
}

</style>
<style>
.flatpickr-day.hidden {
  display: block;
}

.select2-results__option {
  width: unset !important;
  word-break: break-word;
}

</style>